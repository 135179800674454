import {
    MM_CHANGES_DATA,
    MM_CHANGES_DATA_ERR
  } from "../../../actions/common/types";
  
  export default function(state = {}, action) {
    switch (action.type) {
      case MM_CHANGES_DATA:
        // console.log(action.payload);
        return action.payload;
      case MM_CHANGES_DATA_ERR:
        // console.log(action.payload.response);
        return action.payload.response;
      default:
        return state;
    }
  }
  