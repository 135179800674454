import { DOWNLOAD_DOCUMENT_AS_FILE, DOWNLOAD_DOCUMENT_AS_FILE_ERR } from '../../actions/common/types';

export default function(state = [], action){

    switch(action.type){
        case DOWNLOAD_DOCUMENT_AS_FILE:
            // //console.log(action.payload);
            return action.payload;
        case DOWNLOAD_DOCUMENT_AS_FILE_ERR:
            // //console.log(action.payload.response);
            return action.payload.response;
        default:
            return state;
    }
}