import { REGISTRATION_PROCESS_TABLE_REQUEST, REGISTRATION_PROCESS_TABLE_REQUEST_ERR } from '../../../actions/common/types';

export default function(state = [], action){

    switch(action.type){
        case REGISTRATION_PROCESS_TABLE_REQUEST:
            return action.payload;
        case REGISTRATION_PROCESS_TABLE_REQUEST_ERR:
            return action.payload.response;
        default:
            return state;
    }
}