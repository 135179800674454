import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import '../../../style/style.css';
import '../../../style/responsive.css';

import styled from "styled-components";
import Logodtmtitle from "./logodtmtitle";
const Wrapper = styled.section`
        height: 372px;
        width: 70%;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 4px 14px 0 rgba(0,0,0,0.3);
        display: block;
        margin: 0 auto;
        margin-top: 25px;
`;

class errorMessage extends Component {

    componentDidMount = () => {
        setTimeout(function () { window.location = `/tesbit/dtm/index` }, 5000);
    };

    state = {};

    render = () =>
    (
        <div>
             <Logodtmtitle/>
            <div style={{ paddingTop: 10 }}>
                    <Wrapper>
                        <div style={{ paddingTop: 25 }} className="steps-content">
                            <span style={{ textAlign: 'center', fontSize: 17, color: "#f44336" }}>DENİZ TELSİZİ-YENİLEME İŞLEMİ</span>
                        </div>
                        <div className="screencenter">
                            <span>Ödeme işleminiz başarısız olduğu için yenileme işlemi ekranına yönlendiriliyorsunuz.</span>
                        </div>
                    </Wrapper>
            </div>
        </div>
    );
}

function mapStateToProps(state) { }

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            errorMessage
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(errorMessage);
