//PROD
export const routeUrl = "https://payment-client.bthk.org/api";
export const bthkDtmResultRouteUrl =
  "https://payment-client.bthk.org/api/DtmPayment/PaymentResultProcess";
export const bthkDtmReqRequestRouteUrl =
  "https://payment-client.bthk.org/api/DtmPayment/PaymentReqResultProcess";
export const bthkUrl = "https://pos.bthk.org/prod/test_odeme_al.php";
//TEST
// export const bthkDtmResultRouteUrl = "https://pay-api-test.bthk.org/api/DtmPayment/PaymentResultProcess";
// export const bthkDtmReqRequestRouteUrl = "https://pay-api-test.bthk.org/api/DtmPayment/PaymentReqResultProcess";
// export const bthkUrl = "https://pos.bthk.org/test/test_odeme_al.php";
// export const routeUrl = "https://pay-api-test.bthk.org/api";

//LOCAL
// export const routeUrl = "http://localhost:51428/api";

export const DTM = "DTMPayment";
