import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import styled from "styled-components";

import MainTesbitApp from "./components/App.js";

import Index from "./components/tesbit/dtm/index.js";
import RegistrationProcess from "./components/tesbit/dtm/registrationprocess.js";
import CertificateApprovalProcess from "./components/tesbit/dtm/certificateapprovalprocess.js";
import ErrorMessage from "./components/tesbit/dtm/errorMessage.js";
import SuccessMessage from "./components/tesbit/dtm/successMessage.js";
const RouterWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

class Routes extends Component {
  render() {
    return (
        <Router>
          <RouterWrapper>
            <div>
              <Switch>
                <Route exact={true} path="/tesbit/dtm/index" component={Index} />
                <Route exact={true} path="/tesbit/dtm/registrationprocess" component={RegistrationProcess} />
                <Route exact={true} path="/tesbit/dtm/certificateapprovalprocess" component={CertificateApprovalProcess} />
                <Route exact={true} path="/tesbit/dtm/errorMessage" component={ErrorMessage} />
                <Route exact={true} path="/tesbit/dtm/successMessage/:paymentid" component={SuccessMessage} />
                <Route exact={true} path="/" component={MainTesbitApp} />
                <Route component={MainTesbitApp} />
              </Switch>
            </div>
          </RouterWrapper>
        </Router>
    );
  }
}
export default Routes;
