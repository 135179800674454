   import { combineReducers } from "redux";
 import SeacrhRegistrationRequestProcessReducer from "./tesbit/dtm/SeacrhRegistrationRequestProcessReducer";
 import RegistrationProcessTableRequestReducer from "./tesbit/dtm/RegistrationProcessTableRequestReducer";
 import DownloadDocumentAsFileReducer from "./common/downloadDocumentAsFileReducer";
 import CancelRegistrationProcessRequestReducer from "./tesbit/dtm/CancelRegistrationProcessRequestReducer";
 import AgainRegistrationProcessRequestReducer from "./tesbit/dtm/AgainRegistrationProcessRequestReducer";
 import MmChangesDataReducer from "./tesbit/dtm/mmChangesDataReducer";

const rootReducer = combineReducers({
   seacrhRegistrationRequestProcessResult: SeacrhRegistrationRequestProcessReducer,
   registrationProcessTableRequestResult: RegistrationProcessTableRequestReducer,
   downloadDocumentAsFileResult: DownloadDocumentAsFileReducer,
   cancelRegistrationProcessRequestResult: CancelRegistrationProcessRequestReducer,
   againRegistrationProcessRequestResult: AgainRegistrationProcessRequestReducer,
   mmChangesDataResult: MmChangesDataReducer

});
export default rootReducer;
