import React, { Component } from "react";
import "../../../style/style.css";
import "../../../style/responsive.css";
import "../../../styles/index.css";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Logodtmtitle from "./logodtmtitle";
import {
  Input,
  Form,
  Col,
  Row,
  Radio,
  Button,
  Spin,
  Table,
  Modal,
  notification,
  List,
  Checkbox,
  Alert,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import SearchRegistrationRequestProcess from "../../../actions/tesbit/dtm/SearchRegistrationRequestProcessAction";
import RegistrationProcessTableRequest from "../../../actions/tesbit/dtm/RegistrationProcessTableRequestAction";
import CancelRegistrationProcessRequest from "../../../actions/tesbit/dtm/CancelRegistrationProcessRequestAction";
import AgainRegistrationProcessRequest from "../../../actions/tesbit/dtm/AgainRegistrationProcessRequestAction";
import { isMobile } from "react-device-detect";
import {
  bthkDtmResultRouteUrl,
  bthkDtmReqRequestRouteUrl,
  bthkUrl,
} from "../../../env";
const RadioGroup = Radio.Group;
const FormItem = Form.Item;

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 8 },
  },
};

const Wrapper = styled.section`
  width: 92%;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.3);
  display: block;
  margin: 0 auto;
`;

class registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      mainTableColumns: this.mainTableColumns,
      mainDtmTableColumns: this.mainDtmTableColumns,
      mainTableData: this.mainTableData,
      mainTablePaymentData: this.mainTablePaymentData,
      mainTablePaymentColumns: this.mainTablePaymentColumns,
      mainDtmTablePaymentColumns: this.mainDtmTablePaymentColumns,
      mainCostTableData: this.mainCostTableData,
      mainCostTableColumns: this.mainCostTableColumns,
      green: "#9FFF8A",
      red: "#FF8A94",
      selectedRowKeys: [],
      wirelessDeviceAddInfoInputs: [],
      dtmApplicationList: [],
      dtmApplicationFlagList: [],
      dtmNrEmailAddressList: [],
      selecteddtmNrEmailAddressList: [],
      dtmNrPhoneNoList: [],
      selecteddtmNrPhoneNoList: [],
      paymentReqList: [],
      process_Type: "",
      name_Surname: "",
      amount: "",
      project_Id: "",
      payment_Id: "",
      remarks: "",
      module_Id: "",
      payment_Req_Url: "",
      payment_Result_Url: "",
      user_Key: "",
      document_Num: "",
      formData: null,
    };
  }
  mainTableColumns = [
    {
      title: "Deniz Telsizleri Seri No",
      dataIndex: "denizTelsizleriSeriNo",
      key: "denizTelsizleriSeriNo",
    },
    {
      title: "Ad",
      dataIndex: "ad",
      key: "ad",
    },
    {
      title: "Soyad",
      dataIndex: "soyad",
      key: "soyad",
    },
    {
      title: "Kimlik Numarası",
      dataIndex: "kimlikNumarasi",
      key: "kimlikNumarasi",
    },
    {
      title: "Grup_Id",
      dataIndex: "groupId",
      key: "groupId",
    },
    {
      title: "Tekne Numarası",
      dataIndex: "tekneNumarasi",
      key: "tekneNumarasi",
    },
    {
      title: "Tekne Adı",
      dataIndex: "tekneAdi",
      key: "tekneAdi",
    },
    {
      title: "Tekne kullanım Amacı",
      dataIndex: "tekneKullanimAmaci",
      key: "tekneKullanimAmaci",
    },
  ];
  mainDtmTableColumns = [
    {
      title: "Deniz Telsizleri Seri No",
      dataIndex: "denizTelsizleriSeriNo",
      key: "denizTelsizleriSeriNo",
    },
    {
      title: "Ad",
      dataIndex: "ad",
      key: "ad",
    },
    {
      title: "Soyad",
      dataIndex: "soyad",
      key: "soyad",
    },
    {
      title: "Tekne Numarası",
      dataIndex: "tekneNumarasi",
      key: "tekneNumarasi",
    },
    {
      title: "Tekne Adı",
      dataIndex: "tekneAdi",
      key: "tekneAdi",
    },
    {
      title: "Tekne kullanım Amacı",
      dataIndex: "tekneKullanimAmaci",
      key: "tekneKullanimAmaci",
    },
  ];
  mainTableData = [];

  mainTablePaymentData = [];
  mainTablePaymentColumns = [
    {
      title: "Deniz Telsizleri Seri No",
      dataIndex: "denizTelsizleriSeriNo",
      key: "denizTelsizleriSeriNo",
    },
    {
      title: "Ad",
      dataIndex: "ad",
      key: "ad",
    },
    {
      title: "Soyad",
      dataIndex: "soyad",
      key: "soyad",
    },
    {
      title: "Kimlik Numarası",
      dataIndex: "kimlikNumarasi",
      key: "kimlikNumarasi",
    },
    {
      title: "Tekne Numarası",
      dataIndex: "tekneNumarasi",
      key: "tekneNumarasi",
    },
    {
      title: "Tekne Adı",
      dataIndex: "tekneAdi",
      key: "tekneAdi",
    },
    {
      title: "Tekne kullanım Amacı",
      dataIndex: "tekneKullanimAmaci",
      key: "tekneKullanimAmaci",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
    },
  ];

  mainDtmTablePaymentColumns = [
    {
      title: "Deniz Telsizleri Seri No",
      dataIndex: "denizTelsizleriSeriNo",
      key: "denizTelsizleriSeriNo",
    },
    {
      title: "Ad",
      dataIndex: "ad",
      key: "ad",
    },
    {
      title: "Soyad",
      dataIndex: "soyad",
      key: "soyad",
    },
    {
      title: "Tekne Numarası",
      dataIndex: "tekneNumarasi",
      key: "tekneNumarasi",
    },
    {
      title: "Tekne Adı",
      dataIndex: "tekneAdi",
      key: "tekneAdi",
    },
    {
      title: "Tekne kullanım Amacı",
      dataIndex: "tekneKullanimAmaci",
      key: "tekneKullanimAmaci",
    },
    {
      title: "",
      dataIndex: "status",
      key: "status",
    },
  ];

  mainCostTableColumns = [
    {
      title: "Sıra",
      dataIndex: "sira",
      key: "sira",
    },
    {
      title: "Marka",
      dataIndex: "marka",
      key: "marka",
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Türü",
      dataIndex: "turu",
      key: "turu",
    },
    {
      title: "Ruhsat Ücreti(₺)",
      dataIndex: "ruhsatUcreti",
      key: "ruhsatUcreti",
    },
    {
      title: "Kullanım Ücreti(₺)",
      dataIndex: "kullanimUcreti",
      key: "kullanimUcreti",
    },
    {
      title: "KDV Oranı",
      dataIndex: "kdvOrani",
      key: "kdvOrani",
    },
    {
      title: "KDV Tutarı(₺)",
      dataIndex: "kdvTutari",
      key: "kdvTutari",
    },
    {
      title: "KDV`li Toplam(₺)",
      dataIndex: "kdvToplam",
      key: "kdvToplam",
    },
  ];
  mainCostTableData = [];

  UNSAFE_componentWillMount = () => {
    this.reloadComponent();
  };

  componentDidMount() {
    this.initInputState();
    this.createWirelessDeviceAddInfoInputs();
  }

  reloadComponent = () => {
    if (
      this.props.mmChangesDataResult.SelectedProcessId !== null &&
      this.props.mmChangesDataResult.BoadIdNumber !== null &&
      this.props.mmChangesDataResult.BoadIdNumber !== "" &&
      ((this.props.mmChangesDataResult.MwdSrlNum !== null &&
        this.props.mmChangesDataResult.MwdSrlNum !== "") ||
        (this.props.mmChangesDataResult.IdNum !== null &&
          this.props.mmChangesDataResult.IdNum !== ""))
    ) {
      this.searchRegistrationRequestProcess();
    } else {
      notification.error({
        message: "Lütfen seçtiğiniz kriterlere göre boş alanları doldurunuz!",
        placement: "bottomRight",
        duration: 10,
        style: { backgroundColor: this.state.red },
      });
      window.location = `/tesbit/dtm/index`;
    }
  };

  initInputState = () => {
    this.setState({
      isRegistrationRenewalVisible: false,
      selectedRowKeys: [],
      dtmNrFirstName: "",
      dtmNrLastName: "",
      dtmNrIdentityNo: "",
      dtmNrBoatNo: "",
      dtmNrBoatName: "",
      dtmNrBoatLength: 0,
      dtmNrBoatUsageType: null,
      dtmNrDescription: "",
      dtmSearchSerialNo: "",
      dtmSearchIdentityNo: "",
      dtmSearchBoatNo: "",
      dtmNrGroupId: 0,
      wirelessDeviceAddInfoInputs: [],
      dtmNrEmailAddressList: [],
      selecteddtmNrEmailAddressList: [],
      dtmNrPhoneNoList: [],
      selecteddtmNrPhoneNoList: [],
      deviceCnt: "Telsizine",
    });
  };

  initValidation = () => {
    this.setState({
      dtmNrPhoneNoTouched: false,
      dtmNrEmailAddressTouched: false,
    });
  };

  change = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.onChange(name, value);
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onChangeDtmNrPhoneNo = (e) => {
    if (e.target.value.length > 10) return;
    this.setState({
      dtmNrPhoneNo: e.target.value,
      dtmNrPhoneNoTouched: e.target.value !== "" ? true : false,
    });
    this.validateDtmNrPhoneAddress(e.target.value);
  };

  validateDtmNrPhoneAddress = (phone) => {
    if (phone.match(/^[0-9]*$/) && phone.length === 10)
      this.setState({ dtmNrPhoneNoTouched: true });
    else this.setState({ dtmNrPhoneNoTouched: false });
  };

  addNewDtmNrPhoneNo = () => {
    var dtmNrPhoneNoList = this.state.dtmNrPhoneNoList;
    var isdtmNrPhoneNoListExist = dtmNrPhoneNoList.filter(
      (phone) => phone === this.state.dtmNrPhoneNo
    );

    if (isdtmNrPhoneNoListExist.length === 0) {
      dtmNrPhoneNoList.push(this.state.dtmNrPhoneNo);
      this.setState({ dtmNrPhoneNoList, dtmNrPhoneNo: "" });
    } else {
      notification.error({
        message:
          "Eklemek istediğiniz Telefon Numarası, Telefon Numarası listesinde listesinde bulunmaktadır!",
        placement: "bottomRight",
        duration: 10,
        style: { backgroundColor: this.state.red },
      });
    }
  };

  onChangeSelectedDtmNrPhoneNo = (phone) => {
    var dtmNrPhoneNoList = this.state.dtmNrPhoneNoList;
    var selecteddtmNrPhoneNoList = this.state.selecteddtmNrPhoneNoList;
    var phoneIndex = dtmNrPhoneNoList.findIndex((ephone) => ephone === phone);
    var selectedIndex = selecteddtmNrPhoneNoList.findIndex(
      (ephone) => ephone === phone
    );

    if (phoneIndex >= 0 && selectedIndex < 0)
      selecteddtmNrPhoneNoList.push(phone);
    else selecteddtmNrPhoneNoList.splice(selectedIndex, 1);

    this.setState({ selecteddtmNrPhoneNoList });
  };

  removeDtmNrPhoneNoAddress = () => {
    var dtmNrPhoneNoList = this.state.dtmNrPhoneNoList;
    var selecteddtmNrPhoneNoList = this.state.selecteddtmNrPhoneNoList;

    for (let i = 0; i < selecteddtmNrPhoneNoList.length; i++) {
      if (dtmNrPhoneNoList.length > 1) {
        var index = dtmNrPhoneNoList.findIndex(
          (ephone) => ephone === selecteddtmNrPhoneNoList[i]
        );
        dtmNrPhoneNoList.splice(index, 1);
      }
    }

    this.setState({ dtmNrPhoneNoList, selecteddtmNrPhoneNoList: [] });
  };

  handlePhoneInfiniteOnLoad = () => {
    let data = this.state.dtmNrPhoneNo;
    this.setState({
      loading: true,
    });
    if (data.length > 14) {
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }
  };

  onChangeDtmNrEmailAddress = (e) => {
    this.setState({
      dtmNrEmailAddress: e.target.value,
      dtmNrEmailAddressTouched: e.target.value !== "" ? true : false,
    });
    this.validateDtmNrEmailAddress(e.target.value);
  };

  validateDtmNrEmailAddress = (mail) => {
    //mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    if (mail.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/))
      this.setState({ dtmNrEmailAddressTouched: true });
    else this.setState({ dtmNrEmailAddressTouched: false });
  };

  addNewDtmNrEmailAddress = () => {
    var dtmNrEmailAddressList = this.state.dtmNrEmailAddressList;
    var isdtmNrEmailAddressListExist = dtmNrEmailAddressList.filter(
      (mail) => mail === this.state.dtmNrEmailAddress
    );

    if (isdtmNrEmailAddressListExist.length === 0) {
      dtmNrEmailAddressList.push(this.state.dtmNrEmailAddress);
      this.setState({ dtmNrEmailAddressList, dtmNrEmailAddress: "" });
    } else {
      notification.error({
        message: "Eklemek istediğiniz Eposta, Eposta listesinde bulunmaktadır!",
        placement: "bottomRight",
        duration: 10,
        style: { backgroundColor: this.state.red },
      });
    }
  };

  onChangeSelectedDtmNrEmailAddress = (mail) => {
    var dtmNrEmailAddressList = this.state.dtmNrEmailAddressList;
    var selecteddtmNrEmailAddressList =
      this.state.selecteddtmNrEmailAddressList;
    var mailIndex = dtmNrEmailAddressList.findIndex((email) => email === mail);
    var selectedIndex = selecteddtmNrEmailAddressList.findIndex(
      (email) => email === mail
    );

    if (mailIndex >= 0 && selectedIndex < 0)
      selecteddtmNrEmailAddressList.push(mail);
    else selecteddtmNrEmailAddressList.splice(selectedIndex, 1);

    this.setState({ selecteddtmNrEmailAddressList });
  };

  removeDtmNrEmailAddress = () => {
    var dtmNrEmailAddressList = this.state.dtmNrEmailAddressList;
    var selecteddtmNrEmailAddressList =
      this.state.selecteddtmNrEmailAddressList;

    for (let i = 0; i < selecteddtmNrEmailAddressList.length; i++) {
      if (dtmNrEmailAddressList.length > 0) {
        var index = dtmNrEmailAddressList.findIndex(
          (email) => email === selecteddtmNrEmailAddressList[i]
        );
        dtmNrEmailAddressList.splice(index, 1);
      }
    }

    this.setState({ dtmNrEmailAddressList, selecteddtmNrEmailAddressList: [] });
  };

  showThisModal = (modalType, selectedId) => {
    var changeDetail = this.state.dtmApplicationList.filter(
      (x) => x.id === selectedId
    );
    this.setState({ mwdApplicationDetail: changeDetail[0] });
    switch (modalType) {
      case "applicationRegistrationRenewalModal":
        if (this.state.selectedRowKeys.length === 0) {
          notification.error({
            message: "Lütfen veri seçiniz",
            placement: "bottomRight",
            duration: 10,
            style: { backgroundColor: this.state.red },
          });
        } else {
          if (
            changeDetail[0].usage_Type !== 2 ||
            changeDetail[0].mwd_Type !== 1 ||
            changeDetail[0].mwd_Type !== 0
          ) {
            var groupID = changeDetail[0].group_Id;

            var dtmdata = [];
            for (let b = 0; b < this.state.selectedRowKeys.length; b++) {
              var groupDetail = this.state.dtmApplicationList.filter(
                (t) => t.id === this.state.selectedRowKeys[b]
              );
              dtmdata.push(groupDetail);
            }

            if (this.state.selectedRowKeys.length > 1) {
              this.setState({
                deviceCnt: "Telsizlerine",
              });
            } else {
              this.setState({
                deviceCnt: "Telsizine",
              });
            }
            let wirelessDeviceAddInfoInputs =
              this.state.wirelessDeviceAddInfoInputs;
            wirelessDeviceAddInfoInputs = [];

            for (var i = 0; i < dtmdata.length; i++) {
              let wirelessDeviceAddInfoInputObj = {
                dtmNrBrandText: dtmdata[i][0].mwd_Brand,
                dtmNrModelText: dtmdata[i][0].mwd_Model,
                dtmNrMaxOutputPower: dtmdata[i][0].mwd_Max_Output_Power,
                dtmNrSerialNo: dtmdata[i][0].mwd_Srl_Num,
                dtmNrType: dtmdata[i][0].mwd_Type,
                dtmNrApprovalResult: dtmdata[i][0].approval_Result,
              };
              wirelessDeviceAddInfoInputs.push(wirelessDeviceAddInfoInputObj);
            }

            var dtmEmailList = [];
            if (changeDetail[0].email !== null) {
              var emailList = changeDetail[0].email.split(",");
              emailList.forEach((x) => {
                dtmEmailList.push(x);
              });
            }
            var phoneList = [];
            if (changeDetail[0].phone !== null) {
              var dtmPhoneList = changeDetail[0].phone.split(",");
              dtmPhoneList.forEach((x) => {
                phoneList.push(x);
              });
            }

            this.setState({
              isTableListRegistrationReneval: true,
              dtmNrFirstName: changeDetail[0].name,
              dtmNrLastName: changeDetail[0].surname,
              dtmNrIdentityNo: changeDetail[0].id_Num,
              dtmNrPhoneNoList: phoneList,
              dtmNrEmailAddressList: dtmEmailList,
              dtmNrBoatNo: changeDetail[0].boat_Id_Number,
              dtmNrBoatName: changeDetail[0].name_Of_Ship_Hull,
              dtmNrBoatLength: changeDetail[0].length_Of_Hull,
              dtmNrBoatUsageType: changeDetail[0].usage_Type,
              wirelessDeviceAddInfoInputs: wirelessDeviceAddInfoInputs,
              dtmNrDescription: changeDetail[0].notes,
              dtmNrGroupId: groupID,
            });
          } else {
            notification.error({
              message:
                "Bu Kayıt için Yenileme yapılamaz! Lütfen BTHK ya başvurunuz!",
              placement: "bottomRight",
              duration: 10,
              style: { backgroundColor: this.state.red },
            });
          }
        }
        break;
      default:
        break;
    }
  };
  closeThisModal = (modalType) => {
    switch (modalType) {
      case "applicationRegistrationRenewalModal":
        this.initInputState();
        this.setState({ isTableListRegistrationReneval: false });
        break;
      case "registrationRequestCostModal":
        this.setState({
          isRegistrationRequestCostModal: false,
        });
        break;
      case "registrationPaymentRequestCostModal":
        this.setState({
          isRegistrationPaymentRequestCostModal: false,
        });
        break;
      case "cancelRegistrationProcessRequestModal":
        this.setState({
          isCancelRegistrationProcessRequestModal: false,
        });
        break;
      default:
        break;
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

  handleInputChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    //e.preventDefault();
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    //console.log("formData",formData);
    this.form.reset();
  };

  fillMainTable = (dtmAppList) => {
    this.mainTableData = [];

    var row = {};
    dtmAppList.forEach((r) => {
      row = {
        key: r.id,
        denizTelsizleriSeriNo: r.mwd_Srl_Num,
        ad: r.name,
        soyad: r.surname,
        kimlikNumarasi: r.id_Num,
        tekneNumarasi: r.boat_Id_Number,
        tekneAdi: r.name_Of_Ship_Hull,
        tekneKullanimAmaci:
          r.usage_Type === 2
            ? "Balıkçılık"
            : r.usage_Type === 1
            ? "Gezi/Ticari"
            : r.usage_Type === 0
            ? "Eğitim/Spor"
            : null,
        groupId: r.group_Id,
      };
      this.mainTableData.push(row);
    });
    this.setState({ mainTableData: this.mainTableData });
  };

  fillMainPaymentTable = (dtmAppList) => {
    this.mainTablePaymentData = [];

    var row = {};
    dtmAppList.forEach((r) => {
      if (r.payment_Id === 0) {
        row = {
          key: r.id,
          denizTelsizleriSeriNo: r.mwd_Srl_Num,
          ad: r.name,
          soyad: r.surname,
          kimlikNumarasi: r.id_Num,
          tekneNumarasi: r.boat_Id_Number,
          tekneAdi: r.name_Of_Ship_Hull,
          tekneKullanimAmaci:
            r.usage_Type === 2
              ? "Balıkçılık"
              : r.usage_Type === 1
              ? "Gezi/Ticari"
              : r.usage_Type === 0
              ? "Eğitim/Spor"
              : null,
          status: "ÖDEME İÇİN BANKADAN ONAY BEKLENMEKTEDİR",
        };

        this.mainTablePaymentData.push(row);
      } else {
        var isRowExist = this.mainTablePaymentData.filter(
          (x) => x.key === r.id
        ).length;
        var isChildRowExist = this.mainTablePaymentData.filter((x) => {
          return (
            x.children !== undefined &&
            x.children.filter((y) => y.key === r.id).length
          );
        }).length;

        if (isRowExist === 0 && isChildRowExist === 0) {
          var dtmAppParent = dtmAppList.filter(
            (x) => x.id === r.id && x.payment_Id === r.payment_Id
          )[0];
          var dtmAppListChildGroup = dtmAppList.filter(
            (x) => x.id !== r.id && x.payment_Id === r.payment_Id
          );

          var childList = [];

          dtmAppListChildGroup.forEach((dtm) => {
            var child = {
              key: dtm.id,
              denizTelsizleriSeriNo: dtm.mwd_Srl_Num,
              ad: dtm.name,
              soyad: dtm.surname,
              kimlikNumarasi: dtm.id_Num,
              tekneNumarasi: dtm.boat_Id_Number,
              tekneAdi: dtm.name_Of_Ship_Hull,
              tekneKullanimAmaci:
                dtm.usage_Type === 2
                  ? "Balıkçılık"
                  : dtm.usage_Type === 1
                  ? "Gezi/Ticari"
                  : dtm.usage_Type === 0
                  ? "Eğitim/Spor"
                  : null,
              status: "ÖDEME İÇİN BANKADAN ONAY BEKLENMEKTEDİR",
              parentId: dtmAppParent.id,
            };

            childList.push(child);
          });

          row = {
            key: r.id,
            denizTelsizleriSeriNo: r.mwd_Srl_Num,
            ad: r.name,
            soyad: r.surname,
            kimlikNumarasi: r.id_Num,
            tekneNumarasi: r.boat_Id_Number,
            tekneAdi: r.name_Of_Ship_Hull,
            tekneKullanimAmaci:
              r.usage_Type === 2
                ? "Balıkçılık"
                : r.usage_Type === 1
                ? "Gezi/Ticari"
                : r.usage_Type === 0
                ? "Eğitim/Spor"
                : null,
            status: "ÖDEME İÇİN BANKADAN ONAY BEKLENMEKTEDİR",
            children: childList,
          };
          this.mainTablePaymentData.push(row);
        }
      }
    });
    this.setState({
      mainTablePaymentData: this.mainTablePaymentData,
      rowSelection: this.state.rowSelection,
    });
  };

  // fillMainPaymentTable = (dtmAppList) => {
  //   this.mainTablePaymentData = [];

  //   var row = {};
  //   dtmAppList.forEach(r => {
  //       row = {
  //         key: r.id,
  //         denizTelsizleriSeriNo: r.mwd_Srl_Num,
  //         ad: r.name,
  //         soyad: r.surname,
  //         kimlikNumarasi: r.id_Num,
  //         tekneNumarasi: r.boat_Id_Number,
  //         tekneAdi: r.name_Of_Ship_Hull,
  //         tekneKullanimAmaci: r.usage_Type === 2 ? 'Balıkçılık' : r.usage_Type === 1 ? 'Gezi/Ticari' : r.usage_Type === 0 ? 'Eğitim/Spor' : null,
  //         paymentId: r.payment_Id,
  //         status: "ÖDEME İÇİN BANKADAN ONAY BEKLENMEKTEDİR"
  //       };
  //       this.mainTablePaymentData.push(row);
  //   });
  //   this.setState({
  //     mainTablePaymentData: this.mainTablePaymentData,
  //   });
  // }

  fillMainCostTable = (dtmAppList) => {
    this.mainCostTableData = [];

    var row = {};
    dtmAppList.forEach((r) => {
      row = {
        key: r.mwd_Application_Id,
        sira: r.order_Id,
        marka: r.mwd_Brand,
        model: r.mwd_Model,
        turu:
          r.mwd_Type === 0
            ? "MOBIL_UYDU_TERMINAL"
            : r.mwd_Type === 1
            ? "SEYRUSEFER_YARDIMCI"
            : r.mwd_Type === 2
            ? "SABIT"
            : r.mwd_Type === 3
            ? "EL_TIPI"
            : null,
        ruhsatUcreti: r.permit_Cost,
        kullanimUcreti: r.usage_Cost,
        kdvOrani: r.tax_Rate,
        kdvTutari: r.tax_Usage_Cost,
        kdvToplam: r.cumulative_Cost,
        totalCost: r.total_Cost,
      };
      this.mainCostTableData.push(row);
    });
    this.setState({ mainCostTableData: this.mainCostTableData });
  };

  searchRegistrationRequestProcess = async () => {
    var Formfields = this.getFormFields();
    this.setState({ isLoading: true });
    await this.props.SearchRegistrationRequestProcess(Formfields).then(() => {
      if (this.props.seacrhRegistrationRequestProcessResult.status === 200) {
        this.setState({
          appStatusId:
            this.props.seacrhRegistrationRequestProcessResult.data.appStatusId,
        });
        if (
          this.props.seacrhRegistrationRequestProcessResult.data
            .dtmApplicationPaymentFlag1List.length === 0
        ) {
          this.fillMainTable(
            this.props.seacrhRegistrationRequestProcessResult.data
              .dtmApplicationPaymentFlag0List
          );
          this.setState({
            isLoading: false,
            isRegistrationRequestFlag0Modal: true,
            dtmApplicationList:
              this.props.seacrhRegistrationRequestProcessResult.data
                .dtmApplicationPaymentFlag0List,
          });
        } else {
          this.fillMainPaymentTable(
            this.props.seacrhRegistrationRequestProcessResult.data
              .dtmApplicationPaymentFlag1List
          );
          // var selectedRowKeys = [];
          // if(this.props.seacrhRegistrationRequestProcessResult.data.dtmApplicationPaymentFlag1List.length === 1){
          //   this.props.seacrhRegistrationRequestProcessResult.data.dtmApplicationPaymentFlag1List.forEach(r => {
          //     selectedRowKeys.push(r.id)
          //     this.setState({
          //       selectedRowKeys:selectedRowKeys,
          //     });
          //   });
          // }
          if (
            this.props.seacrhRegistrationRequestProcessResult.data
              .dtmApplicationPaymentFlag1List.length > 1
          ) {
            this.setState({
              deviceCnt: "Telsizlerine",
            });
          } else {
            this.setState({
              deviceCnt: "Telsizine",
            });
          }
          this.setState({
            isLoading: false,
            isRegistrationRequestFlag1Modal: true,
            dtmApplicationFlagList:
              this.props.seacrhRegistrationRequestProcessResult.data
                .dtmApplicationPaymentFlag1List,
          });
        }
      } else if (
        this.props.seacrhRegistrationRequestProcessResult.status === 202
      ) {
        this.setState({ isLoading: false });
        window.location = `/tesbit/dtm/index`;
      } else if (
        this.props.seacrhRegistrationRequestProcessResult.status === 400
      ) {
        notification.error({
          message: this.props.seacrhRegistrationRequestProcessResult.data,
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
        this.setState({ isLoading: false });
        setTimeout(function () {
          window.location = `/tesbit/dtm/index`;
        }, 5000);
      }
    });
  };
  getFormFields = () => {
    return {
      SelectedProcessId: this.props.mmChangesDataResult.SelectedProcessId,
      MwdSrlNum: this.props.mmChangesDataResult.MwdSrlNum,
      IdNum: this.props.mmChangesDataResult.IdNum,
      BoadIdNumber: this.props.mmChangesDataResult.BoadIdNumber,
      CertificateNumber: this.props.mmChangesDataResult.CertificateNumber,
    };
  };

  registrationProcessTableRequest = async () => {
    var Formfields = this.getFormProperties();
    this.setState({ isLoading: true });
    //console.log("Formfields", Formfields)
    await this.props.RegistrationProcessTableRequest(Formfields).then(() => {
      if (this.props.registrationProcessTableRequestResult.status === 200) {
        //console.log("this.props.registrationProcessTableRequestResult", this.props.registrationProcessTableRequestResult);
        this.fillMainCostTable(
          this.props.registrationProcessTableRequestResult.data
        );
        this.setState({
          isLoading: false,
          isRegistrationRequestCostModal: true,
          totalCost:
            this.props.registrationProcessTableRequestResult.data[0].total_Cost,
        });
      } else if (
        this.props.registrationProcessTableRequestResult.status === 400
      ) {
        notification.error({
          message: this.props.registrationProcessTableRequestResult.data,
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
        this.setState({
          isLoading: false,
          isCancelRegistrationProcessRequestModal: false,
          isRegistrationPaymentRequestCostModal: false,
          isRegistrationRequestCostModal: false,
          isTableListRegistrationReneval: false,
          isRegistrationRequestFlag0Modal: false,
          isRegistrationRequestFlag1Modal: false,
          mwdSrlNum: null,
          idNum: null,
          boadIdNumber: null,
        });
        setTimeout(function () {
          window.location = `/tesbit/dtm/index`;
        }, 5000);
      }
    });
  };

  getFormProperties = () => {
    return {
      Keys: 1, //Kullanım Yenile butonu ücret hesaplama
      SelectedRowKeys: this.state.selectedRowKeys,
      DtmPhoneNo: this.state.dtmNrPhoneNoList,
      DtmEmailAddress: this.state.dtmNrEmailAddressList,
    };
  };

  registrationCostProcessTableRequest = async () => {
    var Formfields = this.getCostProperties();
    this.setState({ isLoading: true });
    await this.props.RegistrationProcessTableRequest(Formfields).then(() => {
      if (this.props.registrationProcessTableRequestResult.status === 200) {
        //console.log("this.props.registrationProcessTableRequestResult", this.props.registrationProcessTableRequestResult);
        var promise = new Promise((resolve, reject) => {
          this.setState({
            process_Type: "1",
            name_Surname:
              this.props.registrationProcessTableRequestResult.data.name_Surname.toString(),
            amount:
              this.props.registrationProcessTableRequestResult.data.total_Cost.toString(),
            project_Id:
              this.props.registrationProcessTableRequestResult.data.project_Id.toString(),
            payment_Id:
              this.props.registrationProcessTableRequestResult.data.payment_Id.toString(),
            remarks: "Deniz Telsizi Yıllık kullanım ücreti yenileme ödemesi",
            module_Id:
              this.props.registrationProcessTableRequestResult.data.module_Id.toString(),
            payment_Req_Url: bthkDtmReqRequestRouteUrl.toString(),
            payment_Result_Url: bthkDtmResultRouteUrl.toString(),
            user_Key:
              this.props.registrationProcessTableRequestResult.data.user_Key.toString(),
            document_Num:
              this.props.registrationProcessTableRequestResult.data.bthkPaymentIdNum.toString(),
          });
          resolve(true);
        });
        promise.then(() => {
          this.setState({
            isLoading: false,
            isRegistrationPaymentRequestCostModal: true,
          });
        });
      } else if (
        this.props.registrationProcessTableRequestResult.status === 400
      ) {
        notification.error({
          message: this.props.registrationProcessTableRequestResult.data,
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
        this.setState({
          isLoading: false,
          isCancelRegistrationProcessRequestModal: false,
          isRegistrationPaymentRequestCostModal: false,
          isRegistrationRequestCostModal: false,
          isTableListRegistrationReneval: false,
          isRegistrationRequestFlag0Modal: false,
          isRegistrationRequestFlag1Modal: false,
          mwdSrlNum: null,
          idNum: null,
          boadIdNumber: null,
        });
      }
    });
  };

  getCostProperties = () => {
    return {
      Keys: 2, //Ödeme Adımı ile Devam Et
      SelectedRowKeys: this.state.selectedRowKeys,
      DtmPhoneNo: this.state.dtmNrPhoneNoList,
      DtmEmailAddress: this.state.dtmNrEmailAddressList,
    };
  };

  cancelRegistrationProcessRequest = async () => {
    var Formfields = this.getCancelProperties();
    this.setState({ isLoading: true });
    await this.props.CancelRegistrationProcessRequest(Formfields).then(() => {
      if (this.props.cancelRegistrationProcessRequestResult.status === 200) {
        //console.log("this.props.cancelRegistrationProcessRequestResult", this.props.cancelRegistrationProcessRequestResult);
        var promise = new Promise((resolve, reject) => {
          this.setState({
            process_Type: "3",
            name_Surname:
              this.props.cancelRegistrationProcessRequestResult.data[0].name_Surname.toString(),
            amount:
              this.props.cancelRegistrationProcessRequestResult.data[0].total_Cost.toString(),
            project_Id:
              this.props.cancelRegistrationProcessRequestResult.data[0].project_Id.toString(),
            payment_Id:
              this.props.cancelRegistrationProcessRequestResult.data[0].payment_Id.toString(),
            remarks: "Deniz Telsizi Yıllık kullanım ücreti yenileme ödemesi",
            module_Id:
              this.props.cancelRegistrationProcessRequestResult.data[0].module_Id.toString(),
            payment_Req_Url: bthkDtmReqRequestRouteUrl.toString(),
            payment_Result_Url: bthkDtmResultRouteUrl.toString(),
            user_Key:
              this.props.cancelRegistrationProcessRequestResult.data[0].user_Key.toString(),
            document_Num:
              this.props.cancelRegistrationProcessRequestResult.data[0].bthkPaymentIdNum.toString(),
          });
          resolve(true);
        });
        promise.then(() => {
          this.setState({
            isLoading: false,
            isCancelRegistrationProcessRequestModal: true,
          });
        });
      } else if (
        this.props.cancelRegistrationProcessRequestResult.status === 400
      ) {
        notification.error({
          message: this.props.cancelRegistrationProcessRequestResult.data,
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
        this.setState({ isLoading: false });
        setTimeout(function () {
          window.location = `/tesbit/dtm/index`;
        }, 5000);
      }
    });
  };

  getCancelProperties = () => {
    return {
      Keys: 3, //Cancel edilecek ise
      SelectedRowKeys: this.state.selectedRowKeys,
    };
  };

  againRegistrationProcessRequest = async () => {
    var Formfields = this.getAgainProperties();
    this.setState({ isLoading: true });
    await this.props.AgainRegistrationProcessRequest(Formfields).then(() => {
      if (this.props.againRegistrationProcessRequestResult.status === 200) {
        //console.log("this.props.againRegistrationProcessRequestResult", this.props.againRegistrationProcessRequestResult);
        var promise = new Promise((resolve, reject) => {
          this.setState({
            process_Type: "2",
            name_Surname:
              this.props.againRegistrationProcessRequestResult.data[0].name_Surname.toString(),
            amount:
              this.props.againRegistrationProcessRequestResult.data[0].total_Cost.toString(),
            project_Id:
              this.props.againRegistrationProcessRequestResult.data[0].project_Id.toString(),
            payment_Id:
              this.props.againRegistrationProcessRequestResult.data[0].payment_Id.toString(),
            remarks:
              "Deniz Telsizi Yıllık kullanım ücreti yenileme ödemesi".toString(),
            module_Id:
              this.props.againRegistrationProcessRequestResult.data[0].module_Id.toString(),
            payment_Req_Url: bthkDtmReqRequestRouteUrl.toString(),
            payment_Result_Url: bthkDtmResultRouteUrl.toString(),
            user_Key:
              this.props.againRegistrationProcessRequestResult.data[0].user_Key.toString(),
            document_Num:
              this.props.againRegistrationProcessRequestResult.data[0].bthkPaymentIdNum.toString(),
          });
          resolve(true);
        });
        promise.then(() => {
          this.setState({
            isLoading: false,
            isRegistrationPaymentRequestCostModal: true,
          });
        });
      } else if (
        this.props.againRegistrationProcessRequestResult.status === 400
      ) {
        notification.error({
          message: this.props.againRegistrationProcessRequestResult.data,
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
        this.setState({ isLoading: false });
        setTimeout(function () {
          window.location = `/tesbit/dtm/index`;
        }, 5000);
      }
    });
  };

  getAgainProperties = () => {
    return {
      Keys: 4, //Tekrar edilecek ise
      SelectedRowKeys: this.state.selectedRowKeys,
    };
  };

  createWirelessDeviceAddInfoInputs() {
    return this.state.wirelessDeviceAddInfoInputs.map((el, i) => (
      <div key={i}>
        <h3>Telsiz Cihaz Bilgileri</h3>
        <FormItem {...formItemLayout} label="Marka">
          <Input
            disabled
            value={this.state.wirelessDeviceAddInfoInputs[i].dtmNrBrandText}
            placeholder="Marka"
          />
        </FormItem>
        <FormItem {...formItemLayout} label="Model">
          <Input
            value={this.state.wirelessDeviceAddInfoInputs[i].dtmNrModelText}
            disabled
            placeholder="Model"
          />
        </FormItem>
        <div>
          <FormItem {...formItemLayout} label="Max. Çıkış Gücü(W)">
            <Input
              value={
                this.state.wirelessDeviceAddInfoInputs[i].dtmNrMaxOutputPower
              }
              disabled
              placeholder="Max. Çıkış Gücü(W)"
            />
          </FormItem>
          <FormItem {...formItemLayout} label="Seri Numarası">
            <Input
              value={this.state.wirelessDeviceAddInfoInputs[i].dtmNrSerialNo}
              disabled
              placeholder="Seri Numarası"
            />
          </FormItem>
          <FormItem>
            <h3>Tipi</h3>
            <RadioGroup
              value={this.state.wirelessDeviceAddInfoInputs[i].dtmNrType}
              disabled
            >
              <Radio value={3}>El Tipi </Radio>
              <Radio value={2}>Sabit </Radio>
              <Radio value={1}>Seyrüsefer Yardımcı </Radio>
              <Radio value={0}>Mobil Uydu Terminal </Radio>
            </RadioGroup>
          </FormItem>
        </div>
      </div>
    ));
  }

  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {},
      onSelect: (record, selected, selectedRows) => {
        var selectedRowKeys = [];
        if (selectedRows.length > 1) {
          notification.error({
            message: "Birden fazla seçemezsiniz!",
            placement: "bottomRight",
            duration: 10,
            style: { backgroundColor: this.state.red },
          });
        } else {
          for (var i = 0; i < selectedRows.length; i++) {
            selectedRowKeys.push(selectedRows[i].key);
            this.setState({
              choose: selectedRows[i].key,
              isRegistrationRenewalVisible: true,
            });
          }
        }

        this.setState({ selectedRowKeys: selectedRowKeys });
      },
      onSelectAll: (selected, selectedRows, changeRows) => {},
      selectedRowKeys: this.state.selectedRowKeys,
    };

    const rowSelectionIdentityBoat = {
      onChange: (selectedRowKeys, selectedRows) => {},
      onSelect: (record, selected, selectedRows) => {
        var selectedRowKeys = [];
        for (var i = 0; i < selectedRows.length; i++) {
          selectedRowKeys.push(selectedRows[i].key);
          this.setState({
            choose: selectedRows[i].key,
            isRegistrationRenewalVisible: true,
          });
        }

        this.setState({ selectedRowKeys: selectedRowKeys });
      },
      onSelectAll: (selected, selectedRows, changeRows) => {},
      selectedRowKeys: this.state.selectedRowKeys,
    };

    const rowSelectionCancelIdentityBoat = {
      onChange: (selectedRowKeys, selectedRows) => {},
      onSelect: (record, selected, selectedRows) => {
        var parentRow = this.state.mainTablePaymentData.filter(
          (x) => x.key === record.parentId
        );

        if (parentRow.length !== 0) record = parentRow[0];

        var selectedRowKeys = [];
        for (let i = 0; i < selectedRows.length; i++) {
          selectedRowKeys.push(record.key);
          this.setState({
            choose: record.key,
            isChangeRequestVisible: true,
          });
        }

        if (record.children !== undefined && record.children.length > 0) {
          if (selected) {
            for (let j = 0; j < record.children.length; j++) {
              selectedRowKeys.push(record.children[j].key);
            }
          } else {
            selectedRowKeys = [];
            for (let j = 0; j >= record.children.length; --j) {
              selectedRowKeys.pop(record.children[j].key);
            }
          }
        }
        this.setState({ selectedRowKeys: selectedRowKeys });
      },
      selectedRowKeys: this.state.selectedRowKeys,
    };

    return (
      <Spin tip="Lütfen Bekleyin..." spinning={this.state.isLoading}>
        <Logodtmtitle />
        <Wrapper>
          <div style={{ paddingTop: 25 }} className="steps-content ">
            <span
              style={{ textAlign: "center", fontSize: 17, color: "#f44336" }}
            >
              DENİZ TELSİZİ-YENİLEME İŞLEMİ
            </span>
          </div>
          <div style={{ marginTop: 25 }}>
            <Form>
              {this.state.appStatusId === 6 ? (
                <Row type="flex" justify="space-around">
                  <Col span={15}>
                    <Alert
                      message={`Seçmiş olduğunuz telsiz cihazları için kullanım ücretinin ödenmesi gereken son tarih dolmuş olduğundan telsiz (ruhsat) ve kullanma izniniz iptal olmuştur. Seçilmiş olan telsiz cihazlarınızın yeniden ruhsatlandırılıp kullanılabilmesi için yıllık kullanım ücretinin yanında ruhsat bedeli de alınacaktır. `}
                      type="info"
                      showIcon
                    />
                  </Col>
                </Row>
              ) : null}
              {this.state.isRegistrationRequestFlag0Modal === true ? (
                <div className="steps-content-color">
                  <Row>
                    <Col lg={24} align="left" style={{ arginTop: 24 }}>
                      <Button type="primary" shape="round" disabled={true}>
                        Toplam {this.mainTableData.length} Kayıt{" "}
                      </Button>
                    </Col>
                  </Row>
                  <Table
                    rowSelection={
                      this.props.mmChangesDataResult.SelectedProcessId === 1
                        ? rowSelection
                        : rowSelectionIdentityBoat
                    }
                    columns={
                      this.props.mmChangesDataResult.SelectedProcessId === 1
                        ? this.mainDtmTableColumns
                        : this.mainTableColumns
                    }
                    dataSource={this.mainTableData}
                    style={{ whiteSpace: "nowrap" }}
                    scroll={{ x: true, y: false }}
                    size="small"
                    locale={{
                      emptyText: "Görüntülenecek Veri Bulunmamaktadır",
                    }}
                  />
                  <Button
                    type="primary"
                    loading={this.state.isLoading}
                    hidden={this.state.selectedRowKeys.length === 0}
                    disabled={this.state.selectedRowKeys.length === 0}
                    style={{ height: isMobile ? 55 : 45, borderRadius: 10 }}
                    onClick={() =>
                      this.showThisModal(
                        "applicationRegistrationRenewalModal",
                        this.state.choose,
                        this.state.selectedRowKeys
                      )
                    }
                  >
                    Seçilen Telsiz Cihazı{isMobile ? <br /> : ""} İçin Yenileme
                    Yap
                  </Button>
                </div>
              ) : null}
              {this.state.isRegistrationRequestFlag1Modal === true ? (
                <div className="steps-content-color">
                  <Row>
                    <Col lg={24} align="left" style={{ arginTop: 24 }}>
                      <Button type="primary" shape="round" disabled={true}>
                        Toplam {this.mainTablePaymentData.length} Kayıt{" "}
                      </Button>
                    </Col>
                  </Row>
                  <Table
                    rowSelection={rowSelectionCancelIdentityBoat}
                    columns={
                      this.props.mmChangesDataResult.SelectedProcessId === 1
                        ? this.mainDtmTablePaymentColumns
                        : this.mainTablePaymentColumns
                    }
                    dataSource={this.mainTablePaymentData}
                    style={{ whiteSpace: "nowrap" }}
                    scroll={{ x: true, y: false }}
                    size="small"
                    locale={{
                      emptyText: "Görüntülenecek Veri Bulunmamaktadır",
                    }}
                  />
                  <Button
                    type="primary"
                    loading={this.state.isLoading}
                    hidden={this.state.selectedRowKeys.length === 0}
                    disabled={this.state.selectedRowKeys.length === 0}
                    style={{
                      height: isMobile ? 70 : 40,
                      width: isMobile ? 240 : 400,
                      borderRadius: 10,
                      marginLeft: isMobile ? 10 : 25,
                    }}
                    onClick={this.againRegistrationProcessRequest}
                  >
                    Bekleyen Ödemeyi İptal Et {isMobile ? <br /> : ""} ve
                    Yeniden Ödeme İşlemi Başlat
                  </Button>
                  <Button
                    type="primary"
                    danger
                    loading={this.state.isLoading}
                    hidden={this.state.selectedRowKeys.length === 0}
                    disabled={this.state.selectedRowKeys.length === 0}
                    style={{
                      height: isMobile ? 70 : 40,
                      width: isMobile ? 240 : 400,
                      borderRadius: 10,
                      marginLeft: isMobile ? 10 : 25,
                      marginTop: isMobile ? 10 : 0,
                    }}
                    onClick={this.cancelRegistrationProcessRequest}
                  >
                    Kullanım Ücreti Yenileme{isMobile ? <br /> : ""} İşlemini
                    İptal Et
                  </Button>
                </div>
              ) : null}
            </Form>
          </div>
        </Wrapper>
        <Modal
          zIndex={1002}
          title="DENİZ TELSİZLERİ - ONLINE KULLANIM YENİLEME"
          visible={this.state.isTableListRegistrationReneval}
          onCancel={() =>
            this.closeThisModal("applicationRegistrationRenewalModal")
          }
          width={isMobile ? "98%" : "70%"}
          maskStyle={{ backgroundColor: "#444955a4" }}
          // bodyStyle={{ backgroundColor: "#FFFFFF" }}
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            overflow: "hidden",
          }}
          footer={[
            <Button
              key="kapat"
              loading={this.state.isLoading}
              size="large"
              danger
              shape="round"
              onClick={() =>
                this.closeThisModal("applicationRegistrationRenewalModal")
              }
            >
              Kapat
            </Button>,
          ]}
        >
          <form>
            <div className="steps-content-color">
              <h3>Deniz Telsizi Sahibinin Bilgileri</h3>
              <div style={{ paddingTop: 20 }}>
                <FormItem {...formItemLayout} label="Ad">
                  <Input
                    disabled
                    value={this.state.dtmNrFirstName}
                    placeholder="Ad"
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Soyad">
                  <Input
                    disabled
                    value={this.state.dtmNrLastName}
                    placeholder="Soyad"
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Telefon Numarası">
                  <Input
                    value={this.state.dtmNrPhoneNo}
                    onChange={this.onChangeDtmNrPhoneNo}
                    placeholder=""
                  />
                  <Row type="flex" justify="end">
                    <Col lg={{ span: 4 }}>
                      <Button
                        key="kaydet"
                        loading={this.state.isLoading}
                        type="primary"
                        disabled={
                          !this.state.dtmNrPhoneNoTouched ||
                          this.state.dtmNrPhoneNo === ""
                        }
                        onClick={this.addNewDtmNrPhoneNo}
                      >
                        Ekle
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
                <FormItem
                  required="true"
                  {...formItemLayout}
                  label="Telefon Numarası Listesi"
                >
                  <div className="demo-infinite-container-height">
                    <InfiniteScroll
                      initialLoad={false}
                      pageStart={0}
                      loadMore={this.handlePhoneInfiniteOnLoad}
                      hasMore={this.state.hasMore}
                      useWindow={false}
                    >
                      <List
                        locale={{ emptyText: "Telefon Listesi Boş" }}
                        bordered
                        dataSource={this.state.dtmNrPhoneNoList}
                        renderItem={(phone) => (
                          <List.Item key={phone}>
                            <div>
                              <Checkbox
                                onChange={() =>
                                  this.onChangeSelectedDtmNrPhoneNo(phone)
                                }
                              >
                                {phone}
                              </Checkbox>
                            </div>
                          </List.Item>
                        )}
                      />
                    </InfiniteScroll>
                  </div>
                  <Row type="flex" justify="end">
                    <Col lg={{ span: 4 }}>
                      <Button
                        key="kaydet"
                        loading={this.state.isLoading}
                        type="primary"
                        disabled={
                          this.state.dtmNrPhoneNoList.length === 1 ||
                          this.state.dtmNrPhoneNoList.length === 1
                            ? true
                            : false
                        }
                        onClick={this.removeDtmNrPhoneNoAddress}
                      >
                        Çıkar
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
                <FormItem {...formItemLayout} label="Eposta Adresi">
                  <Input
                    value={this.state.dtmNrEmailAddress}
                    onChange={this.onChangeDtmNrEmailAddress}
                    placeholder=""
                  />
                  <Row type="flex" justify="end">
                    <Col lg={{ span: 4 }}>
                      <Button
                        key="kaydet"
                        loading={this.state.isLoading}
                        type="primary"
                        disabled={
                          !this.state.dtmNrEmailAddressTouched ||
                          this.state.dtmNrEmailAddress === ""
                        }
                        onClick={this.addNewDtmNrEmailAddress}
                      >
                        Ekle
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
                <FormItem {...formItemLayout} label="Eposta Adresi Listesi">
                  <div className="demo-infinite-container-height">
                    <InfiniteScroll
                      initialLoad={false}
                      pageStart={0}
                      loadMore={this.handleInfiniteOnLoad}
                      hasMore={this.state.hasMore}
                      useWindow={false}
                    >
                      <List
                        locale={{ emptyText: "Email Listesi Boş" }}
                        bordered
                        dataSource={this.state.dtmNrEmailAddressList}
                        renderItem={(mail) => (
                          <List.Item key={mail}>
                            <div>
                              <Checkbox
                                onChange={() =>
                                  this.onChangeSelectedDtmNrEmailAddress(mail)
                                }
                              >
                                {mail}
                              </Checkbox>
                            </div>
                          </List.Item>
                        )}
                      />
                    </InfiniteScroll>
                  </div>
                  <Row type="flex" justify="end">
                    <Col lg={{ span: 4 }}>
                      <Button
                        key="kaydet"
                        loading={this.state.isLoading}
                        type="primary"
                        disabled={
                          this.state.dtmNrEmailAddressList.length === 0
                            ? true
                            : false || this.state.dtmNrIdentityNoListTouched
                        }
                        onClick={this.removeDtmNrEmailAddress}
                      >
                        Çıkar
                      </Button>
                    </Col>
                  </Row>
                </FormItem>
              </div>
            </div>
            <div className="steps-content-color">
              <h3>Deniz Telsizinin Kullanılacağı Tekneye Ait Bilgiler</h3>
              <div>
                <FormItem {...formItemLayout} label="Tekne Numarası">
                  <Input
                    disabled
                    value={this.state.dtmNrBoatNo}
                    placeholder="Tekne Numarası"
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Tekne Adı">
                  <Input
                    disabled
                    value={this.state.dtmNrBoatName}
                    placeholder="Tekne Adı"
                  />
                </FormItem>
                <FormItem {...formItemLayout} label="Tekne Uzunluğu (mt)">
                  <Input
                    disabled
                    value={this.state.dtmNrBoatLength}
                    placeholder="Tekne Uzunluğu (mt)"
                  />
                </FormItem>
                <FormItem>
                  <h3>Tekne Kullanım Amacı</h3>
                  <RadioGroup disabled value={this.state.dtmNrBoatUsageType}>
                    <Radio value={2}>Balıkçılık</Radio>
                    <Radio value={1}>Gezi/Ticari</Radio>
                    <Radio value={0}>Eğitim/Spor</Radio>
                  </RadioGroup>
                </FormItem>
              </div>
            </div>
            <div className="steps-content-color">
              {this.createWirelessDeviceAddInfoInputs()}
            </div>
            <div style={{ paddingTop: 20 }}>
              <FormItem>
                <Row justify="center" align="top">
                  <Col span={isMobile ? 24 : 7}>
                    <Button
                      type="primary"
                      style={{ height: 50, width: 250, borderRadius: 10 }}
                      loading={this.state.isLoading}
                      onClick={this.registrationProcessTableRequest}
                    >
                      Kullanım Yenile
                    </Button>
                  </Col>
                  <Col
                    span={isMobile ? 24 : 7}
                    style={{ paddingTop: isMobile ? 10 : 0 }}
                  >
                    <Button
                      type="danger"
                      size="large"
                      style={{ height: 50, width: 250, borderRadius: 10 }}
                      loading={this.state.isLoading}
                      onClick={() =>
                        this.closeThisModal(
                          "applicationRegistrationRenewalModal"
                        )
                      }
                    >
                      İptal
                    </Button>
                  </Col>
                </Row>
              </FormItem>
            </div>
          </form>
        </Modal>
        <Modal
          zIndex={1007}
          title="DENİZ TELSİZLERİ - ONLINE KULLANIM YENİLEME"
          visible={this.state.isRegistrationRequestCostModal}
          onCancel={() => this.closeThisModal("registrationRequestCostModal")}
          width={isMobile ? "98%" : "75%"}
          maskStyle={{ backgroundColor: "#444955a4" }}
          bodyStyle={{ backgroundColor: "#FFFFFF" }}
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            overflow: "hidden",
          }}
          footer={[
            <Button
              key="kapat"
              danger
              shape="round"
              size="large"
              loading={this.state.isLoading}
              onClick={() =>
                this.closeThisModal("registrationRequestCostModal")
              }
            >
              Kapat
            </Button>,
          ]}
        >
          <div className="steps-content-color">
            <Row>
              <Col lg={24} align="left" style={{ marginTop: 24 }}>
                <Button type="primary" shape="round" disabled={true}>
                  Toplam {this.mainTableData.length} Kayıt{" "}
                </Button>
              </Col>
            </Row>
            <Table
              columns={this.mainCostTableColumns}
              pagination={false}
              dataSource={this.mainCostTableData}
              style={{ whiteSpace: "nowrap" }}
              scroll={{ x: true, y: false }}
              size="small"
              locale={{ emptyText: "Görüntülenecek Veri Bulunmamaktadır" }}
            />
            <FormItem
              style={{ marginTop: 24 }}
              {...formItemLayout}
              label="Genel Toplam(₺)"
            >
              <Input disabled size="large" value={this.state.totalCost} />
            </FormItem>
            <Row type="flex" justify="space-around" style={{ marginTop: 24 }}>
              <Col span={24}>
                <Button
                  type="primary"
                  style={{ height: 40, borderRadius: 10 }}
                  loading={this.state.isLoading}
                  onClick={this.registrationCostProcessTableRequest}
                >
                  Ödeme Adımı İle Devam Et
                </Button>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          zIndex={1009}
          title="DENİZ TELSİZLERİ - ONLINE KULLANIM YENİLEME"
          visible={this.state.isRegistrationPaymentRequestCostModal}
          onCancel={() =>
            this.closeThisModal("registrationPaymentRequestCostModal")
          }
          width={isMobile ? "98%" : "65%"}
          maskStyle={{ backgroundColor: "#444955a4" }}
          bodyStyle={{ backgroundColor: "#FFFFFF" }}
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            overflow: "hidden",
          }}
          footer={[
            <Button
              key="kapat"
              danger
              shape="round"
              size="large"
              loading={this.state.isLoading}
              onClick={() =>
                this.closeThisModal("registrationPaymentRequestCostModal")
              }
            >
              Kapat
            </Button>,
          ]}
        >
          <div className="steps-content-color">
            <span>{`Deniz ${this.state.deviceCnt} ait yıllık kullanım ücretini ödemek için devam etmek istiyor musunuz?`}</span>
            {/* https://pos.bthk.org/test/test-hkh/test_odeme_al.php - https://pos.bthk.org/prod/test_odeme_al.php*/}
            <form
              action={`${bthkUrl}`}
              method="post"
              ref={(c) => (this.form = c)}
              onSubmit={this.handleSubmit}
            >
              <input
                name="process_Type"
                value={this.state.process_Type}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="name_Surname"
                value={this.state.name_Surname}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="amount"
                value={this.state.amount}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="project_Id"
                value={this.state.project_Id}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="payment_Id"
                value={this.state.payment_Id}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="remarks"
                value={this.state.remarks}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="module_Id"
                value={this.state.module_Id}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="payment_Req_Url"
                value={this.state.payment_Req_Url}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="payment_Result_Url"
                value={this.state.payment_Result_Url}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="user_Key"
                value={this.state.user_Key}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="document_Num"
                value={this.state.document_Num}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <div style={{ marginTop: 20 }}>
                <button
                  disabled={this.state.isLoading === true}
                  type="primary"
                  style={{ height: 40, borderRadius: 10 }}
                >
                  Devam Et
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <Modal
          zIndex={1009}
          title="DENİZ TELSİZLERİ - ONLINE KULLANIM İPTAL İŞLEMİ"
          visible={this.state.isCancelRegistrationProcessRequestModal}
          onCancel={() =>
            this.closeThisModal("cancelRegistrationProcessRequestModal")
          }
          width={isMobile ? "98%" : "65%"}
          maskStyle={{ backgroundColor: "#444955a4" }}
          bodyStyle={{ backgroundColor: "#FFFFFF" }}
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            overflow: "hidden",
          }}
          footer={[
            <Button
              key="kapat"
              danger
              shape="round"
              loading={this.state.isLoading}
              size="large"
              onClick={() =>
                this.closeThisModal("cancelRegistrationProcessRequestModal")
              }
            >
              Kapat
            </Button>,
          ]}
        >
          <div className="steps-content-color">
            <span>
              Deniz Telsizleri yıllık kullanım yenileme işlemini iptal etmek
              için devam etmek istiyor musunuz?”
            </span>
            {/* https://pos.bthk.org/test/test-hkh/test_odeme_al.php - https://pos.bthk.org/prod/test_odeme_al.php*/}
            <form
              action={`${bthkUrl}`}
              method="post"
              ref={(c) => (this.form = c)}
              onSubmit={this.handleSubmit}
            >
              <input
                name="process_Type"
                value={this.state.process_Type}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="name_Surname"
                value={this.state.name_Surname}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="amount"
                value={this.state.amount}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="project_Id"
                value={this.state.project_Id}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="payment_Id"
                value={this.state.payment_Id}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="remarks"
                value={this.state.remarks}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="module_Id"
                value={this.state.module_Id}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="payment_Req_Url"
                value={this.state.payment_Req_Url}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="payment_Result_Url"
                value={this.state.payment_Result_Url}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="user_Key"
                value={this.state.user_Key}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <input
                name="document_Num"
                value={this.state.document_Num}
                hidden="true"
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
              <div style={{ marginTop: 20 }}>
                <button
                  disabled={this.state.isLoading === true}
                  type="primary"
                  style={{ height: 40, borderRadius: 10 }}
                >
                  Devam
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    seacrhRegistrationRequestProcessResult:
      state.seacrhRegistrationRequestProcessResult,
    registrationProcessTableRequestResult:
      state.registrationProcessTableRequestResult,
    cancelRegistrationProcessRequestResult:
      state.cancelRegistrationProcessRequestResult,
    againRegistrationProcessRequestResult:
      state.againRegistrationProcessRequestResult,
    mmChangesDataResult: state.mmChangesDataResult,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      SearchRegistrationRequestProcess,
      RegistrationProcessTableRequest,
      CancelRegistrationProcessRequest,
      AgainRegistrationProcessRequest,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(registration);
