import axios from 'axios';
import { routeUrl,DTM } from '../../../env';
import { CANCEL_REGISTRATION_PROCESS_REQUEST, CANCEL_REGISTRATION_PROCESS_REQUEST_ERR } from '../../common/types';

export default (CancelRegistrationProcessRequest) => async (dispatch) => {

   try{
    axios.defaults.headers.common["Accept-Version"] = 1;
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
       const req = await axios.post(`${routeUrl}/${DTM}/CancelRegistrationProcessRequest`,CancelRegistrationProcessRequest);
    //    console.log("CancelRegistrationProcessRequest",CancelRegistrationProcessRequest);
        dispatch(type(req));
   }
   catch(err){
        dispatch(typeErr(err));
   }
};

const type = req => ({
    type: CANCEL_REGISTRATION_PROCESS_REQUEST,
    payload: req,
});

const typeErr = err => ({
    type: CANCEL_REGISTRATION_PROCESS_REQUEST_ERR,
    payload: err,
});
