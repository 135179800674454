import { SEARCH_REGISTRATION_REQUEST_PROCESS, SEARCH_REGISTRATION_REQUEST_PROCESS_ERR } from '../../../actions/common/types';

export default function(state = [], action){

    switch(action.type){
        case SEARCH_REGISTRATION_REQUEST_PROCESS:
            return action.payload;
        case SEARCH_REGISTRATION_REQUEST_PROCESS_ERR:
            return action.payload.response;
        default:
            return state;
    }
}