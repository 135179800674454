import { AGAIN_REGISTRATION_PROCESS_REQUEST, AGAIN_REGISTRATION_PROCESS_REQUEST_ERR } from '../../../actions/common/types';

export default function(state = [], action){

    switch(action.type){
        case AGAIN_REGISTRATION_PROCESS_REQUEST:
            return action.payload;
        case AGAIN_REGISTRATION_PROCESS_REQUEST_ERR:
            return action.payload.response;
        default:
            return state;
    }
}