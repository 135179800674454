import {MM_CHANGES_DATA,MM_CHANGES_DATA_ERR} from '../../common/types';

export default MmChangesData => async dispatch => {
  try {
    var request = {
      ProcessType: MmChangesData.ProcessType,
      SelectedProcessId: MmChangesData.SelectedProcessId,
      BoadIdNumber: MmChangesData.BoadIdNumber,
      IdNum: MmChangesData.IdNum,
      MwdSrlNum: MmChangesData.MwdSrlNum,
      CertificateNumber: MmChangesData.CertificateNumber
    };
    dispatch(typeMmChangesData(request));
  } catch (err) {
    dispatch(typeMmChangesDataErr(err));
  }
};

const typeMmChangesData = req => ({
  type: MM_CHANGES_DATA,
  payload: req
});

const typeMmChangesDataErr = err => ({
  type: MM_CHANGES_DATA_ERR,
  payload: err
});
