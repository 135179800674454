import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../../../style/style.css";
import "../../../style/responsive.css";
import Logodtmtitle from "./logodtmtitle";
import MMChangesDataAction from "../../../actions/tesbit/dtm/mmChangesDataAction";
import { isMobile } from "react-device-detect";
import {
  Card,
  Input,
  Form,
  Col,
  Row,
  Radio,
  Button,
  notification,
  Alert,
} from "antd";

const RadioGroup = Radio.Group;

const radioStyle = {
  lineHeight: "30px",
};

class index extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      newSelectedProcessId: 1,
      approvalSelectedProcessId: 3,
      newMwdSrlNum: null,
      newIdNum: null,
      newBoadIdNumber: null,
      MwdSrlNum: null,
      approvalIdNum: null,
      approvalBoadIdNumber: null,
      approvalCertificateNumber: `TK-D-${new Date().getFullYear()}/`,
      tkdnumber: `TKD_${new Date().getFullYear()}/`,
      green: "#9FFF8A",
      red: "#FF8A94",
    };
  }

  change = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.onChange(name, value);
  };

  initState = () => {
    this.setState({
      isLoading: false,
      newSelectedProcessId: 1,
      approvalSelectedProcessId: 3,
      newMwdSrlNum: null,
      newIdNum: null,
      newBoadIdNumber: null,
      MwdSrlNum: null,
      approvalIdNum: null,
      approvalBoadIdNumber: null,
      approvalCertificateNumber: `TK-D-${new Date().getFullYear()}/`,
      tkdnumber: `TKD_${new Date().getFullYear()}/`,
    });
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onChangeRadioButton = (e) => {
    this.initState();
    let name = e.target.name;
    let value = e.target.value;
    this.onChange(name, value);
  };

  changesData = async (
    type,
    boadIdNumber,
    idNum,
    mwdSrlNum,
    selectedProcessId,
    certificateNumber
  ) => {
    if (type === "list") {
      if (
        selectedProcessId !== null &&
        selectedProcessId == 6 &&
        boadIdNumber !== null &&
        boadIdNumber !== "" &&
        mwdSrlNum !== null &&
        mwdSrlNum !== "" &&
        idNum !== null &&
        idNum !== ""
      ) {
        this.setState({ isLoading: true });
        await this.props.MMChangesDataAction({
          ProcessType: type,
          SelectedProcessId: selectedProcessId,
          BoadIdNumber: boadIdNumber,
          IdNum: idNum,
          MwdSrlNum: mwdSrlNum,
          CertificateNumber: certificateNumber, //certificateNumber != null ? certificateNumber.split('/')[1] : null
        });
        this.props.history.push(`/tesbit/dtm/registrationprocess`);
      } else if (
        selectedProcessId !== null &&
        selectedProcessId == 1 &&
        boadIdNumber !== null &&
        boadIdNumber !== "" &&
        mwdSrlNum !== null &&
        mwdSrlNum !== ""
      ) {
        this.setState({ isLoading: true });
        await this.props.MMChangesDataAction({
          ProcessType: type,
          SelectedProcessId: selectedProcessId,
          BoadIdNumber: boadIdNumber,
          IdNum: idNum,
          MwdSrlNum: mwdSrlNum,
          CertificateNumber: certificateNumber, //certificateNumber != null ? certificateNumber.split('/')[1] : null
        });
        this.props.history.push(`/tesbit/dtm/registrationprocess`);
      } else if (
        selectedProcessId !== null &&
        selectedProcessId == 2 &&
        boadIdNumber !== null &&
        boadIdNumber !== "" &&
        idNum !== null &&
        idNum !== ""
      ) {
        this.setState({ isLoading: true });
        await this.props.MMChangesDataAction({
          ProcessType: type,
          SelectedProcessId: selectedProcessId,
          BoadIdNumber: boadIdNumber,
          IdNum: idNum,
          MwdSrlNum: mwdSrlNum,
          CertificateNumber: certificateNumber, //certificateNumber != null ? certificateNumber.split('/')[1] : null
        });
        this.props.history.push(`/tesbit/dtm/registrationprocess`);
      } else {
        notification.error({
          message: "Lütfen seçtiğiniz kriterlere göre boş alanları doldurunuz!",
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
      }
    } else if (type === "search") {
      if (
        selectedProcessId !== null &&
        selectedProcessId == 3 &&
        boadIdNumber !== null &&
        boadIdNumber !== "" &&
        mwdSrlNum !== null &&
        mwdSrlNum !== ""
      ) {
        this.setState({ isLoading: true });
        await this.props.MMChangesDataAction({
          ProcessType: type,
          SelectedProcessId: selectedProcessId,
          BoadIdNumber: boadIdNumber,
          IdNum: idNum,
          MwdSrlNum: mwdSrlNum,
          CertificateNumber: certificateNumber, //certificateNumber != null ? certificateNumber.split('/')[1] : null
        });
        this.props.history.push(`/tesbit/dtm/certificateapprovalprocess`);
      } else if (
        selectedProcessId !== null &&
        selectedProcessId == 4 &&
        boadIdNumber !== null &&
        boadIdNumber !== "" &&
        idNum !== null &&
        idNum !== ""
      ) {
        this.setState({ isLoading: true });
        await this.props.MMChangesDataAction({
          ProcessType: type,
          SelectedProcessId: selectedProcessId,
          BoadIdNumber: boadIdNumber,
          IdNum: idNum,
          MwdSrlNum: mwdSrlNum,
          CertificateNumber: certificateNumber, //certificateNumber != null ? certificateNumber.split('/')[1] : null
        });
        this.props.history.push(`/tesbit/dtm/certificateapprovalprocess`);
      } else if (
        selectedProcessId !== null &&
        selectedProcessId == 5 &&
        certificateNumber !== null &&
        certificateNumber !== ""
      ) {
        this.setState({ isLoading: true });
        await this.props.MMChangesDataAction({
          ProcessType: type,
          SelectedProcessId: selectedProcessId,
          BoadIdNumber: boadIdNumber,
          IdNum: idNum,
          MwdSrlNum: mwdSrlNum,
          CertificateNumber: certificateNumber, //certificateNumber != null ? certificateNumber.split('/')[1] : null
        });
        this.props.history.push(`/tesbit/dtm/certificateapprovalprocess`);
      } else {
        notification.error({
          message: "Lütfen seçtiğiniz kriterlere göre boş alanları doldurunuz!",
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
      }
    }
  };
  render = () => {
    const {
      approvalBoadIdNumber,
      approvalMwdSrlNum,
      approvalIdNum,
      approvalCertificateNumber,

      approvalSelectedProcessId,
    } = this.state;
    console.log(this.state.newSelectedProcessId);
    console.log(this.state.newSelectedProcessId === 6);
    return (
      <div>
        <Logodtmtitle />
        <div style={{ paddingTop: 10 }}>
          <Card type="inner">
            <div
              className={
                isMobile
                  ? "screencenter indexWrapperMobil"
                  : "screencenter indexWrapper"
              }
            >
              <Row>
                <div
                  className="indexWrapperBox"
                  style={{
                    textAlign: "center",
                    height: this.state.newSelectedProcessId === 6 ? 335 : 245,
                  }}
                >
                  <div
                    style={{
                      paddingTop:
                        this.state.newSelectedProcessId === 6
                          ? isMobile
                            ? 80
                            : 100
                          : isMobile
                          ? 10
                          : 30,
                    }}
                  >
                    <span className="indexWrapperTextTitleInput">
                      Yenileme İşlemleri
                    </span>
                  </div>
                  <div style={{ paddingTop: isMobile ? 10 : 25 }}>
                    <span className="indexWrapperTextInput">
                      Profesyonel balıkçı tekneleri haricinde diğer teknelerde
                      bulunan telsiz cihazlarının kullanım ücretleri her yıl en
                      geç Şubat Ayı sonuna kadar ödenmelidir.
                    </span>
                  </div>
                </div>
                <div className={isMobile ? "" : "indexWrapperBoxSection"}>
                  <div style={{ paddingTop: isMobile ? 10 : 30 }}>
                    <RadioGroup
                      name="newSelectedProcessId"
                      onChange={(e) => this.onChangeRadioButton(e)}
                      value={this.state.newSelectedProcessId}
                      size="large"
                      style={{
                        display: "-ms-flexbox",
                        textAlign: isMobile ? "center" : "",
                      }}
                    >
                      <Radio style={radioStyle} key={1} value={1}>
                        Telsiz Seri No Ve Tekne Numarasına{" "}
                        {isMobile ? <br /> : ""}Göre Listeleme
                      </Radio>
                      <Radio style={radioStyle} key={2} value={2}>
                        Kimlik Numarası Ve Tekne Numarasına{" "}
                        {isMobile ? <br /> : ""}Göre Listeleme{" "}
                      </Radio>
                      <Radio style={radioStyle} key={6} value={6}>
                        {new Date().getFullYear()} yılı öncesinde iptal olmuş
                        telsizlerin {isMobile ? <br /> : ""}yenilenme işlemi{" "}
                      </Radio>
                    </RadioGroup>
                    {this.state.newSelectedProcessId !== null ? (
                      <div
                        className={isMobile ? "indexWrapperBoxSection" : ""}
                        style={{ paddingTop: "15px" }}
                      >
                        <Row type="flex" justify="space-around">
                          {this.state.newSelectedProcessId === 6 ? (
                            <Alert
                              message="Uyarı: Daha önce izinlendirilmiş telsize ait bilgileri giriniz."
                              type="info"
                              style={{ marginBottom: 5 }}
                            />
                          ) : null}
                          <Col span={isMobile ? 24 : 20}>
                            <Row type="flex" justify="space-around">
                              <Col span={isMobile ? 24 : 10}>
                                {this.state.newSelectedProcessId === 1 ||
                                this.state.newSelectedProcessId === 6 ? ( //Deniz Telsizleri Seri No + Tekne Numarası
                                  <Form.Item>
                                    <Input
                                      name="newMwdSrlNum"
                                      size="large"
                                      value={this.state.newMwdSrlNum}
                                      placeholder="Telsiz Seri Numarası"
                                      onChange={(e) => this.change(e)}
                                    />
                                  </Form.Item>
                                ) : this.state.newSelectedProcessId === 2 ? ( //Kimlik Numarası + Tekne Numarası
                                  <Form.Item>
                                    <Input
                                      name="newIdNum"
                                      size="large"
                                      value={this.state.newIdNum}
                                      placeholder="Kimlik Numarası"
                                      onChange={(e) => this.change(e)}
                                    />
                                  </Form.Item>
                                ) : null}
                              </Col>
                              <Col span={isMobile ? 24 : 10}>
                                <Form.Item>
                                  <Input
                                    name="newBoadIdNumber"
                                    size="large"
                                    value={this.state.newBoadIdNumber}
                                    placeholder="Tekne Numarası"
                                    onChange={(e) => this.change(e)}
                                  />
                                </Form.Item>
                              </Col>
                              {this.state.newSelectedProcessId === 6 ? (
                                <Col span={isMobile ? 24 : 10}>
                                  <Form.Item>
                                    <Input
                                      name="newIdNum"
                                      size="large"
                                      value={this.state.newIdNum}
                                      placeholder="Kimlik Numarası"
                                      onChange={(e) => this.change(e)}
                                    />
                                  </Form.Item>
                                </Col>
                              ) : null}
                            </Row>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-around">
                          <Col span={24}>
                            <Button
                              type={"primary"}
                              loading={this.state.isLoading}
                              style={{
                                height: 50,
                                width: 150,
                                borderRadius: 10,
                                marginBottom: 25,
                                background: "rgb(64, 54, 114)",
                              }}
                              onClick={() =>
                                this.changesData(
                                  "list",
                                  this.state.newBoadIdNumber,
                                  this.state.newIdNum,
                                  this.state.newMwdSrlNum,
                                  this.state.newSelectedProcessId,
                                  null
                                )
                              }
                            >
                              Listele
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Row>
              <hr style={{ height: "2px" }}></hr>
              <Row>
                <div className="indexWrapperBox">
                  <div style={{ paddingTop: isMobile ? 10 : 30 }}>
                    <span className="indexWrapperTextTitleInput">
                      Onay Belgesi Sorgulama
                    </span>
                  </div>
                  <div style={{ paddingTop: isMobile ? 20 : 45 }}>
                    <span className="indexWrapperTextInput">
                      Telsiz kurma ve kullanma izin belgesini sorgulayarak,
                      indirebilirsiniz.
                    </span>
                  </div>
                </div>
                <div className={isMobile ? "" : "indexWrapperBoxSection"}>
                  <div style={{ paddingTop: isMobile ? 10 : 30 }}>
                    <RadioGroup
                      name="approvalSelectedProcessId"
                      onChange={(e) => this.onChangeRadioButton(e)}
                      value={this.state.approvalSelectedProcessId}
                      size="large"
                      style={{
                        display: "-ms-flexbox",
                        textAlign: isMobile ? "center" : null,
                      }}
                    >
                      <Radio style={radioStyle} key={3} value={3}>
                        Telsiz Seri No Ve Tekne Numarasına
                        {isMobile ? <br /> : ""} Göre Listeleme
                      </Radio>
                      <Radio style={radioStyle} key={4} value={4}>
                        Kimlik Numarası Ve Tekne Numarasına
                        {isMobile ? <br /> : ""} Göre Listeleme{" "}
                      </Radio>
                      <Radio style={radioStyle} key={5} value={5}>
                        Onay Belgesi Numarası
                      </Radio>
                    </RadioGroup>
                    {this.state.approvalSelectedProcessId !== null ? (
                      <div className={isMobile ? "indexWrapperBoxSection" : ""}>
                        <Row
                          type="flex"
                          justify="space-around"
                          style={{ marginTop: 20 }}
                        >
                          <Col span={isMobile ? 24 : 18}>
                            <Row type="flex" justify="space-around">
                              <Col span={isMobile ? 24 : 9}>
                                {this.state.approvalSelectedProcessId === 3 ? ( //Deniz Telsizleri Seri No + Tekne Numarası
                                  <Form.Item>
                                    <Input
                                      maxLength="15"
                                      size="large"
                                      name="approvalMwdSrlNum"
                                      value={approvalMwdSrlNum}
                                      placeholder="Telsiz Seri No"
                                      onChange={(e) => this.change(e)}
                                    />
                                  </Form.Item>
                                ) : this.state.approvalSelectedProcessId ===
                                  4 ? ( //Kimlik Numarası + Tekne Numarası
                                  <Form.Item>
                                    <Input
                                      name="approvalIdNum"
                                      size="large"
                                      maxLength="15"
                                      value={approvalIdNum}
                                      placeholder="Kimlik Numarası"
                                      onChange={(e) => this.change(e)}
                                    />
                                  </Form.Item>
                                ) : this.state.approvalSelectedProcessId ===
                                  5 ? ( //Onay Belgesi Numarası
                                  <Form.Item>
                                    <Row>
                                      <Input
                                        maxLength="15"
                                        size="large"
                                        name="approvalCertificateNumber"
                                        value={approvalCertificateNumber}
                                        placeholder="Onay Belgesi Numarası"
                                        onChange={(e) => this.change(e)}
                                      />
                                    </Row>
                                  </Form.Item>
                                ) : null}
                              </Col>
                              {this.state.approvalSelectedProcessId === 3 ||
                              this.state.approvalSelectedProcessId === 4 ? (
                                <Col span={isMobile ? 24 : 9}>
                                  <Form.Item>
                                    <Input
                                      name="approvalBoadIdNumber"
                                      size="large"
                                      value={approvalBoadIdNumber}
                                      placeholder="Tekne Numarası"
                                      onChange={(e) => this.change(e)}
                                    />
                                  </Form.Item>
                                </Col>
                              ) : null}
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: isMobile ? 50 : null }}>
                          <Col span={24}>
                            <Button
                              type={"primary"}
                              loading={this.state.isLoading}
                              style={{
                                height: 50,
                                width: 150,
                                borderRadius: 10,
                                background: "rgb(64, 54, 114)",
                              }}
                              onClick={() =>
                                this.changesData(
                                  "search",
                                  approvalBoadIdNumber,
                                  approvalIdNum,
                                  approvalMwdSrlNum,
                                  approvalSelectedProcessId,
                                  approvalCertificateNumber
                                )
                              }
                            >
                              Arama
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      MMChangesDataAction,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(index);
