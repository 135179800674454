import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import '../../../style/style.css';
import '../../../style/responsive.css';
import styled from "styled-components";
import { Spin} from 'antd';

import Logodtmtitle from "./logodtmtitle";
import MMChangesDataAction from "../../../actions/tesbit/dtm/mmChangesDataAction";

const Wrapper = styled.section`
        height: 372px;
        width: 70%;
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 4px 14px 0 rgba(0,0,0,0.3);
        display: block;
        margin: 0 auto;
        margin-top: 25px;
`;

class successMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedProcessId: null,
            mwdSrlNum: null,
            idNum: null,
            boadIdNumber: null,
            paymentIdKey: null,
            mainTableData: this.mainTableData,
            mainTableColumns: this.mainTableColumns
        };
    }

    componentDidMount = () => {
        var promise = new Promise((resolve, reject) => {
            this.setState({
                paymentIdKey: this.props.match.params.paymentid
            });
            resolve(true);
        })
        promise.then(() => {
            //console.log(this.state.paymentIdKey);
            this.changesData("search", null, null, null, 7, this.state.paymentIdKey);
        });
    };

    state = {};

    changesData = async (type, boadIdNumber, idNum, mwdSrlNum, selectedProcessId, certificateNumber) => {
        this.setState({ isLoading: true });
        //console.log("certificateNumber", certificateNumber);
        await this.props.MMChangesDataAction({
            ProcessType: type,
            SelectedProcessId: selectedProcessId,
            BoadIdNumber: boadIdNumber,
            IdNum: idNum,
            MwdSrlNum: mwdSrlNum,
            CertificateNumber: certificateNumber
        });
        if (type === "search") {
            this.props.history.push(`/tesbit/dtm/certificateapprovalprocess`);
        } else {
            setTimeout(function () { window.location = `/tesbit/dtm/index` }, 5000);
        }
    }

    render() {
        return (
            <Spin tip="Lütfen Bekleyin..." spinning={this.state.isLoading}>
                 <Logodtmtitle/>
                    <Wrapper>
                        <div style={{ paddingTop: 25 }} className="steps-content">
                            <span style={{ textAlign: 'center', fontSize: 17, color: "#f44336" }}>DENİZ TELSİZİ-YENİLEME İŞLEMİ</span>
                        </div>
                        <div className="screencenter">
                            <span>Ödeme işleminiz başarılı bir şekilde tamamlandı. Onay belgesi sorgulama ekranına yönlendiriliyorsunuz.</span>
                        </div>
                    </Wrapper>
            </Spin>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            MMChangesDataAction
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(successMessage);
