import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import BthkLogo from "../../../img/bthk_logo.png";
import '../../../style/style.css';
import '../../../style/responsive.css';
import { Row } from 'antd';
class logodtmtitle extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div>
                <Row>
                    <img
                        src={BthkLogo}
                        className="logostyle"
                        alt="logo" />
                    <div style={{ textAlign: "center", flex: 1, marginTop: isMobile ? '10%' : '5%' }}>
                        <span className="indexTextInput">Deniz Telsizleri Online İşlemler</span>
                    </div>
                </Row>
                <div style={{ boxShadow: "0 10px 20px 0 rgba(0,0,0,0.3)", height: 5, width:'100%'}}></div>
            </div>

        )
    }
}

export default logodtmtitle;