import axios from 'axios';
import { routeUrl,DTM } from '../../../env';
import { SEARCH_REGISTRATION_REQUEST_PROCESS, SEARCH_REGISTRATION_REQUEST_PROCESS_ERR } from '../../common/types';

export default (searchRegistrationRequest) => async (dispatch) => {

   try{
    //    console.log("searchRegistrationRequest",searchRegistrationRequest);
    //    console.log(`${routeUrl}/${DTM}/SearchRegistrationRequestProcess`);
           
       axios.defaults.headers.common["Accept-Version"] = 1;
       axios.defaults.headers.common["Accept"] = "application/json";
       axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
       axios.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
       
       const request = await axios.post(`${routeUrl}/${DTM}/SearchRegistrationRequestProcess`,searchRegistrationRequest);
    //    console.log("request",request);
        dispatch(type(request));
   }
   catch(err){
        dispatch(typeErr(err));
   }
};

const type = req => ({
    type: SEARCH_REGISTRATION_REQUEST_PROCESS,
    payload: req,
});

const typeErr = err => ({
    type: SEARCH_REGISTRATION_REQUEST_PROCESS_ERR,
    payload: err,
});
