export const SEARCH_REGISTRATION_REQUEST_PROCESS = "SEARCH_REGISTRATION_REQUEST_PROCESS";
export const SEARCH_REGISTRATION_REQUEST_PROCESS_ERR = "SEARCH_REGISTRATION_REQUEST_PROCESS_ERR";
export const REGISTRATION_PROCESS_TABLE_REQUEST = "REGISTRATION_PROCESS_TABLE_REQUEST";
export const REGISTRATION_PROCESS_TABLE_REQUEST_ERR = "REGISTRATION_PROCESS_TABLE_REQUEST_ERR";
export const DOWNLOAD_DOCUMENT_AS_FILE = "DOWNLOAD_DOCUMENT_AS_FILE";
export const DOWNLOAD_DOCUMENT_AS_FILE_ERR = "DOWNLOAD_DOCUMENT_AS_FILE_ERR";
export const CANCEL_REGISTRATION_PROCESS_REQUEST = "CANCEL_REGISTRATION_PROCESS_REQUEST";
export const CANCEL_REGISTRATION_PROCESS_REQUEST_ERR = "CANCEL_REGISTRATION_PROCESS_REQUEST_ERR";
export const AGAIN_REGISTRATION_PROCESS_REQUEST = "AGAIN_REGISTRATION_PROCESS_REQUEST";
export const AGAIN_REGISTRATION_PROCESS_REQUEST_ERR = "AGAIN_REGISTRATION_PROCESS_REQUEST_ERR";
export const MM_CHANGES_DATA = "MM_CHANGES_DATA";
export const MM_CHANGES_DATA_ERR = "MM_CHANGES_DATA_ERR";