import { message } from "antd";

export const isDegree = (val) => {
  if (val >= 0 && val <= 360) return true;

  return false;
};
export const downloadOrOpenSelectedPdfFile = (base64Content) => {
  var byteCharacters = atob(base64Content);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var file = new Blob([byteArray], { type: "application/pdf;base64" });
  //console.log("filepdf",file);
  var fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};
// export const convertLatLongToDecimalDegrees = (degree, minute, second) => {
//   return degree + minute / 60 + second / 3600;
// };
export const downloadOrOpenSelectedAnyFile = (base64Content) => {
  var fileName = "";
  var date = new Date();
  var split = base64Content.data.fileName.split(".");
  var dateString =
    date.getFullYear() +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    ("0" + date.getDate()).slice(-2) +
    ("0" + date.getHours()).slice(-2) +
    ("0" + date.getMinutes()).slice(-2) +
    ("0" + date.getSeconds()).slice(-2);
  fileName = `${dateString}_${split[0]}.${split[1]}`;

  const byteCharacters = atob(base64Content.data.fileArray);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: base64Content.data.type });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
  }, 100);
  URL.revokeObjectURL(url);
};

export const findBase64 = (file) => {
  // var file = e.target.files[0]; gelecek file böyle olmalı.
  // //console.log("common findBase64 started");
  let resultFile = {};

  if (file === undefined || file.size === 0) {
    alert("Yüklenen " + file.name + " dosyanın boyutu 0 KB olamaz");
    //console.log("file undefined")
    resultFile = null;
  } else {
    resultFile.fileName = file.name;
    resultFile.size = file.size;
    resultFile.type = file.type;

    var reader = new FileReader();
    reader.onload = function (upload) {
      // //console.log("The file has been read...");
      resultFile.base64 = upload.target.result.split(",")[1];
      // //console.log("resultFile.data_uri", resultFile.base64);
    };
    reader.readAsDataURL(file);
  }
  // //console.log(resultFile);
  return resultFile;
};

export const sleep = (milliseconds) => {
  // //console.log("sleep called ! ");

  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break;
    }
  }
};

export const convertLatLongToDecimalDegrees = (degree, minute, second) => {
  return degree + minute / 60 + second / 3600;
};

export const calculateDaysOfTwoDates = (date1Str, date2Str) => {
  var dt1 = toDate(date1Str);
  var dt2 = toDate(date2Str);
  var totalDays = Math.abs(
    Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    )
  );
  return totalDays;
};

export const toDate = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year, month - 1, day);
};

export const formatStringWithPadding = (padCnt, pad, userStr, padPos) => {
  if (typeof userStr === "undefined") return;

  let formattedStr = userStr;
  for (var i = formattedStr.length; i < padCnt; i++) {
    if (padPos === "l") {
      formattedStr = (pad + formattedStr).slice(-pad.length);
    } else {
      formattedStr = (formattedStr + pad).substring(0, pad.length);
    }
  }

  return formattedStr;
};

export const getNextKey = (index) => {
  var alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  var iteration = parseInt(index / 25);
  var charIndex = index % 25;
  var key = "";

  if (iteration > 1) {
    for (var i = 0; i < iteration; i++) {
      key += alphabet[index];
    }
  }

  key += alphabet[charIndex];
  return key;
};

export const getDynamicComponentKey = (currentKey) => {
  if (currentKey === 0 || currentKey === null || currentKey === undefined)
    return 1;

  return ++currentKey;
};

export const downloadBlobFiles = (blobResult, customFileName = []) => {
  const blob = new Blob([blobResult.data], {
    type: blobResult.headers["content-type"],
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  var fileName = "";
  var date = new Date();

  var dateString =
    date.getFullYear() +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    ("0" + date.getDate()).slice(-2) +
    ("0" + date.getHours()).slice(-2) +
    ("0" + date.getMinutes()).slice(-2) +
    ("0" + date.getSeconds()).slice(-2);

  if (customFileName.length === 0) {
    var fileNameArray = blobResult.headers["content-disposition"]
      .split(";")[1]
      .split("=")[1]
      .split(".");
    var fileNameList = fileNameArray[0].split("_");
    // fileName = `${dateString}_${fileNameList[1]}.${fileNameArray[1]}`;
    fileName = `${dateString}_${fileNameList[1]}`;
  } else {
    //var fileExtension = blobResult.headers["content-disposition"].split(';')[1].split('=')[1].split('.')[1];
    // fileName = `${customFileName.join('_')}_${dateString}.${fileExtension}`;
    fileName = `${customFileName.join("_")}_${dateString}`;
  }

  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const convertBlobToText = (blobResult) => {
  const blob = new Blob([blobResult.data], { type: "text/plain" });
  const reader = new FileReader();

  // This fires after the blob has been read/loaded.
  reader.addEventListener("loadend", (e) => {
    const text = e.srcElement.result;
    // //console.log(text);
    message.error(text);
  });

  // Start reading the blob as text.
  reader.readAsText(blob);
};
