import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class App extends Component {
  render() {
    return (
    <Redirect
            to={{
              pathname: "/tesbit/dtm/index"
            }}
          />
    )
  }
}

function mapStateToProps(state) {
    return {

    };
  }

export default connect(
  mapStateToProps,
  null
)(App);
