import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Logodtmtitle from "./logodtmtitle";
import "../../../style/style.css";
import "../../../style/responsive.css";
import { DownloadOutlined } from "@ant-design/icons";
import {
  /*downloadBlobFiles, convertBlobToText,*/ downloadOrOpenSelectedAnyFile,
} from "./../../common/commonFunctions";

import { Form, Col, Row, Button, Spin, Table, notification } from "antd";

import SearchRegistrationRequestProcess from "../../../actions/tesbit/dtm/SearchRegistrationRequestProcessAction";
import DownloadDocument from "../../../actions/common/downloadDocumentAsFileAction";
import MMChangesDataAction from "../../../actions/tesbit/dtm/mmChangesDataAction";

class certificateapprovalprocess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      // selectedItems: [],
      // revenueYearChoose: [],
      mainTableData: this.mainTableData,
      mainTableColumns: this.mainTableColumns,
      green: "#9FFF8A",
      red: "#FF8A94",
    };
  }

  componentWillMount = () => {
    this.reloadComponent();
  };

  componentDidMount() {
    // this.getYear();
  }

  reloadComponent = () => {
    if (
      (this.props.mmChangesDataResult.SelectedProcessId !== null &&
        this.props.mmChangesDataResult.BoadIdNumber !== null &&
        this.props.mmChangesDataResult.BoadIdNumber !== "" &&
        ((this.props.mmChangesDataResult.MwdSrlNum !== null &&
          this.props.mmChangesDataResult.MwdSrlNum !== "") ||
          (this.props.mmChangesDataResult.IdNum !== null &&
            this.props.mmChangesDataResult.IdNum !== ""))) ||
      (this.props.mmChangesDataResult.SelectedProcessId !== null &&
        this.props.mmChangesDataResult.CertificateNumber !== null &&
        this.props.mmChangesDataResult.CertificateNumber !== "")
    ) {
      this.searchRegistrationRequestProcess();
    } else {
      notification.error({
        message: "Lütfen seçtiğiniz kriterlere göre boş alanları doldurunuz!",
        placement: "bottomRight",
        duration: 10,
        style: { backgroundColor: this.state.red },
      });
      setTimeout(function () {
        window.location = `/tesbit/dtm/index`;
      }, 5000);
    }
  };

  mainTableColumns = [
    {
      title: "Yıl",
      dataIndex: "appDate",
      key: "appDate",
    },
    {
      title: "Online Ödeme Numarası",
      dataIndex: "documnetNum",
      key: "documnetNum",
    },
    {
      title: "Onay Dokümanı",
      dataIndex: "onayDokumanı",
      key: "onayDokumanı",
      render: (text, record) => (
        <div>
          <Button
            shape="round"
            type="ghost"
            size="middle"
            icon={<DownloadOutlined />}
            loading={this.state.isLoading}
            disabled={
              record.approvalDoc === null || record.approvalDoc === ""
                ? true
                : false
            }
            onClick={() => this.downloadDocument(record.approvalDoc)}
          >
            Onay Dokümanı Görüntüle
          </Button>
        </div>
      ),
    },
    {
      title: "Ücret Dokümanı",
      dataIndex: "ucretDokumanı",
      key: "ucretDokumanı",
      render: (text, record) => (
        <div>
          <Button
            shape="round"
            type="ghost"
            size="middle"
            icon={<DownloadOutlined />}
            loading={this.state.isLoading}
            disabled={
              record.costDoc === null || record.costDoc === "" ? true : false
            }
            onClick={() => this.downloadDocument(record.costDoc)}
          >
            Ücret Dokümanı Görüntüle
          </Button>
        </div>
      ),
    },
  ];

  mainTableData = [];

  changesData = async (
    type,
    boadIdNumber,
    idNum,
    mwdSrlNum,
    selectedProcessId,
    certificateNumber
  ) => {
    this.setState({ isLoading: true });
    //console.log("certificateNumber", certificateNumber);
    await this.props.MMChangesDataAction({
      ProcessType: type,
      SelectedProcessId: selectedProcessId,
      BoadIdNumber: boadIdNumber,
      IdNum: idNum,
      MwdSrlNum: mwdSrlNum,
      CertificateNumber: certificateNumber,
    });
    if (type === "list") {
      if (
        selectedProcessId !== null &&
        boadIdNumber !== null &&
        boadIdNumber !== "" &&
        ((mwdSrlNum !== null && mwdSrlNum !== "") ||
          (idNum !== null && idNum !== ""))
      ) {
        this.props.history.push(`/tesbit/dtm/registrationprocess`);
      } else {
        notification.error({
          message: "Lütfen seçtiğiniz kriterlere göre boş alanları doldurunuz!",
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
      }
    }
  };

  change = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.onChange(name, value);
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  // handleChange = selectedItems => {
  //   this.setState({ selectedItems });
  // };

  // getYear =() => {
  //   var revenueYearChoose = [];
  //   for (var i = new Date().getFullYear(); i >= 1900; i--) {
  //     revenueYearChoose.push(i);
  //   }
  //   this.setState({
  //     revenueYearChoose
  //   });
  //   console.log("Options"+ revenueYearChoose)
  // }

  searchRegistrationRequestProcess = async () => {
    var Formfields = this.getFormFields();
    this.setState({ isLoading: true });
    await this.props.SearchRegistrationRequestProcess(Formfields).then(() => {
      if (this.props.seacrhRegistrationRequestProcessResult.status === 200) {
        //console.log("this.props.seacrhRegistrationRequestProcessResult", this.props.seacrhRegistrationRequestProcessResult);
        this.fillMainTable(
          this.props.seacrhRegistrationRequestProcessResult.data
        );
        this.setState({
          isLoading: false,
          isRegistrationRequestModal: true,
        });
      } else if (
        this.props.seacrhRegistrationRequestProcessResult.status === 202
      ) {
        let SelectedProcessId = null;
        if (
          this.props.seacrhRegistrationRequestProcessResult.data
            .boadIdNumber !== null &&
          this.props.seacrhRegistrationRequestProcessResult.data.mwdSrlNum !==
            null
        ) {
          SelectedProcessId = 1;
        } else if (
          this.props.seacrhRegistrationRequestProcessResult.data
            .boadIdNumber !== null &&
          this.props.seacrhRegistrationRequestProcessResult.data.idNum !== null
        ) {
          SelectedProcessId = 2;
        } else {
          notification.error({
            message: "Sistemsel hata oluştu!",
            placement: "bottomRight",
            duration: 10,
            style: { backgroundColor: this.state.red },
          });
        }
        this.changesData(
          "list",
          this.props.seacrhRegistrationRequestProcessResult.data.boadIdNumber,
          this.props.seacrhRegistrationRequestProcessResult.data.idNum,
          this.props.seacrhRegistrationRequestProcessResult.data.mwdSrlNum,
          SelectedProcessId
        );
      } else if (
        this.props.seacrhRegistrationRequestProcessResult.status === 400
      ) {
        notification.error({
          message: this.props.seacrhRegistrationRequestProcessResult.data,
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
        this.setState({ isLoading: false });
        setTimeout(function () {
          window.location = `/tesbit/dtm/index`;
        }, 5000);
      }
    });
  };
  getFormFields = () => {
    let Formfields = {
      SelectedProcessId: this.props.mmChangesDataResult.SelectedProcessId,
      MwdSrlNum: this.props.mmChangesDataResult.MwdSrlNum,
      IdNum: this.props.mmChangesDataResult.IdNum,
      BoadIdNumber: this.props.mmChangesDataResult.BoadIdNumber,
      CertificateNumber: this.props.mmChangesDataResult.CertificateNumber,
      // SelectedItems: this.state.selectedItems
    };
    return Formfields;
  };

  downloadDocument = async (docPath) => {
    this.setState({ isLoading: true });
    await this.props.DownloadDocument({ DocumentPath: docPath }).then(() => {
      if (this.props.downloadDocumentResult.status === 200) {
        //console.log("this.props.downloadDocumentResult", this.props.downloadDocumentResult);
        this.setState({ isLoading: false });
        // window.open('data:' + this.props.downloadDocumentResult.data.type + ';base64,' + this.props.downloadDocumentResult.data.fileArray);
        downloadOrOpenSelectedAnyFile(this.props.downloadDocumentResult);
      } else if (this.props.downloadDocumentResult.status === 400) {
        this.setState({ isLoading: false });
        notification.error({
          message: this.props.downloadDocumentResult.data,
          placement: "bottomRight",
          duration: 10,
          style: { backgroundColor: this.state.red },
        });
      }
    });
  };

  fillMainTable = (dtmAppList) => {
    this.mainTableData = [];

    var row = {};
    dtmAppList.forEach((r) => {
      row = {
        key: r.procDoc.id,
        approvalDoc: r.procDoc.approval_Doc,
        appDate: r.year,
        documnetNum: r.documnetNum,
        costDoc: r.costDoc.cost_Document,
      };
      this.mainTableData.push(row);
    });
  };

  render() {
    // const filteredOptions = this.state.revenueYearChoose.filter(o => !selectedItems.includes(o));
    return (
      <Spin tip="Lütfen Bekleyin..." spinning={this.state.isLoading}>
        <Logodtmtitle />
        <div className="certificateapprowalWrapper">
          <div style={{ paddingTop: 25 }} className="steps-content">
            <span
              style={{ textAlign: "center", fontSize: 17, color: "#f44336" }}
            >
              ONAY BELGESİ SORGULAMA
            </span>
          </div>
          <div style={{ marginTop: 25 }}>
            <Form>
              <div className="steps-content-color">
                <Row>
                  <Col lg={24} align="left" style={{ arginTop: 24 }}>
                    <Button type="primary" shape="round" disabled={true}>
                      Toplam {this.mainTableData.length} Kayıt{" "}
                    </Button>
                  </Col>
                </Row>
                <Table
                  columns={this.mainTableColumns}
                  dataSource={this.mainTableData}
                  style={{ whiteSpace: "nowrap" }}
                  scroll={{ x: true, y: false }}
                  size="large"
                  locale={{ emptyText: "Görüntülenecek Veri Bulunmamaktadır" }}
                />
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state) {
  return {
    seacrhRegistrationRequestProcessResult:
      state.seacrhRegistrationRequestProcessResult,
    downloadDocumentResult: state.downloadDocumentAsFileResult,
    mmChangesDataResult: state.mmChangesDataResult,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      SearchRegistrationRequestProcess,
      DownloadDocument,
      MMChangesDataAction,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(certificateapprovalprocess);
