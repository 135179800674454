import axios from 'axios';
import { routeUrl,DTM } from '../../env';
import { DOWNLOAD_DOCUMENT_AS_FILE, DOWNLOAD_DOCUMENT_AS_FILE_ERR } from './types';


export default (DownloadDocumentPaymentAsFile) => async (dispatch) => {
    try{
        // axios.defaults.headers.common["Accept-Version"] = 1;
        // axios.defaults.headers.common["Accept"] = "application/json";
        // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        // axios.defaults.headers.common["Content-Type"] = "application/json; charset=utf-8";
        const request = await axios({
            url: `${routeUrl}/${DTM}/DownloadDocumentPaymentAsFile`,
            method: 'POST',
            // responseType: 'blob', // important
            data: {DocumentPath: DownloadDocumentPaymentAsFile.DocumentPath}
        });

        dispatch(typeDownloadDocumentAsFile(request));
   }
   catch(err){
        dispatch(typeDownloadDocumentAsFileErr(err));
   }
};

const typeDownloadDocumentAsFile = req => ({
    type: DOWNLOAD_DOCUMENT_AS_FILE,
    payload: req
});

const typeDownloadDocumentAsFileErr = err => ({
    type: DOWNLOAD_DOCUMENT_AS_FILE_ERR,
    payload: err
});